// Chakra imports
import {Flex, Image, Text, useColorModeValue} from '@chakra-ui/react';

// Custom components

export default function Transaction(props: {
  currentPrice: number;
  sum: string;
  tokenAmount: number;
  icon: string;
  name: string;
  [x: string]: any;
}) {
  const {currentPrice, sum, tokenAmount, icon, name, ...rest} = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconBoxBg = useColorModeValue('secondaryGray.300', 'navy.700');

  function formatPrice(price: number): string {
    // For small numbers, keep precision to avoid scientific notation
    if (price < 0.0001) {
      return price.toFixed(8);
    }

    return price.toLocaleString('en-US', {
      minimumFractionDigits: 2, // Ensures that there is always two decimal places
      maximumFractionDigits: 8, // Allows up to 8 decimal places if necessary
    });
  }

  return (
    <Flex justifyContent='center' alignItems='center' w='100%' {...rest}>
      <Image src={icon} h='32px' w='32px' me='20px'/>
      <Flex direction='column' align='start' me='auto'>
        <Text color={textColor} fontSize='md' me='6px' fontWeight='700'>
          {name}
        </Text>
        <Text color='secondaryGray.600' fontSize='sm' fontWeight='500'>
          ${currentPrice && formatPrice(currentPrice)}
        </Text>
      </Flex>
      <Flex direction='column' align='end' ms='auto' me='25px'>
        <Text color={textColor} fontSize='large' fontWeight='700'>
          ${sum}
        </Text>
        <Text color='secondaryGray.500' fontSize='sm' fontWeight='500'>
          {tokenAmount} {name}
        </Text>
      </Flex>
    </Flex>

  );
}
