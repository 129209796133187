export type CurrencyData = {
  readonly value: string;
  readonly label: string;
};

export type GroupedOption = {
  readonly label: string;
  readonly options: readonly CurrencyData[];
};


export const popularCurrencies: readonly CurrencyData[] = [
  {value: 'USD', label: 'USD'},
  {value: 'EUR', label: 'EUR'},
  {value: 'GBP', label: 'GBP'}
];

export const otherCurrencies: readonly CurrencyData[] = [
  {value: 'AED', label: 'AED'},
  {value: 'ARS', label: 'ARS'},
  {value: 'AUD', label: 'AUD'},
  {value: 'BDT', label: 'BDT'},
  {value: 'BHD', label: 'BHD'},
  {value: 'BMD', label: 'BMD'},
  {value: 'BRL', label: 'BRL'},
  {value: 'CAD', label: 'CAD'},
  {value: 'CHF', label: 'CHF'},
  {value: 'CLP', label: 'CLP'},
  {value: 'CNY', label: 'CNY'},
  {value: 'CZK', label: 'CZK'},
  {value: 'DKK', label: 'DKK'},
  {value: 'HKD', label: 'HKD'},
  {value: 'HUF', label: 'HUF'},
  {value: 'IDR', label: 'IDR'},
  {value: 'ILS', label: 'ILS'},
  {value: 'INR', label: 'INR'},
  {value: 'JPY', label: 'JPY'},
  {value: 'KRW', label: 'KRW'},
  {value: 'KWD', label: 'KWD'},
  {value: 'LKR', label: 'LKR'},
  {value: 'MMK', label: 'MMK'},
  {value: 'MXN', label: 'MXN'},
  {value: 'MYR', label: 'MYR'},
  {value: 'NGN', label: 'NGN'},
  {value: 'NOK', label: 'NOK'},
  {value: 'NZD', label: 'NZD'},
  {value: 'PHP', label: 'PHP'},
  {value: 'PKR', label: 'PKR'},
  {value: 'PLN', label: 'PLN'},
  {value: 'RUB', label: 'RUB'},
  {value: 'SAR', label: 'SAR'},
  {value: 'SEK', label: 'SEK'},
  {value: 'SGD', label: 'SGD'},
  {value: 'THB', label: 'THB'},
  {value: 'TRY', label: 'TRY'},
  {value: 'TWD', label: 'TWD'},
  {value: 'UAH', label: 'UAH'},
  {value: 'VEF', label: 'VEF'},
  {value: 'VND', label: 'VND'},
  {value: 'XDR', label: 'XDR'},
  {value: 'ZAR', label: 'ZAR'}
];