import {Icon} from '@chakra-ui/react';
import {MdHome, MdLock, MdSettings} from 'react-icons/md';
import {IoIosSend, IoMdCart} from "react-icons/io";
import { IoBarChartSharp } from "react-icons/io5";


// Admin Imports
import Setup from 'views/admin/setup';
import Fullfill from 'views/admin/notifications';
import Settings from 'views/admin/getpaid';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import StartHere from "./views/admin/starthere";
import Reports from "./views/admin/reports";

const routes = [
	{
		name: 'Start here',
		layout: '/admin',
		path: '/start-here',
		icon: <Icon mb={-1} as={MdHome} width='20px' height='20px' color='inherit' />,
		component: StartHere,
		needsAuth: false,
	},
	{
		name: 'Set up',
		layout: '/admin',
		path: '/set-up',
		icon: <Icon mb={-1} as={MdSettings} width='20px' height='20px' color='inherit' />,
		component: Setup,
		needsAuth: false,
	},
	{
		name: 'Get paid',
		layout: '/admin',
		icon: <Icon mb={-1} as={IoMdCart} width='20px' height='20px' color='inherit' />,
		path: '/get-paid',
		component: Settings,
		needsAuth: true,
	},
	{
		name: 'Notifications',
		layout: '/admin',
		path: '/notifications',
		icon: <Icon mb={-1} as={IoIosSend} width='20px' height='20px' color='inherit' />,
		component: Fullfill,
		needsAuth: true,
		secondary: true
	},
	{
		name: 'Reports',
		layout: '/admin',
		path: '/reports',
		icon: <Icon mb={-1} as={IoBarChartSharp} width='20px' height='20px' color='inherit' />,
		component: Reports,
		needsAuth: true,
		secondary: true
	},
	// {
	// 	name: 'Profile',
	// 	layout: '/admin',
	// 	path: '/profile',
	// 	icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
	// 	component: Profile,
	// 	needsAuth: true,
	// },
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered,
		needsAuth: false,
	},
];

export default routes;
