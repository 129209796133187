import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts'
import {ApexOptions} from "apexcharts";
import Card from "../../../../components/card/Card";
import {Box, Flex, Select, Text, useColorModeValue} from "@chakra-ui/react";
import axios, {AxiosError} from "axios";

interface PieChartProps {
  dataMap: Map<string, number>;
}

const PercentagesPerToken: React.FC = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [dataPercentages, setDataPercentages] = useState<Map<string, number>>();

  // const [totalOrders, setTotalOrders] = useState<number>(0);


  async function getPercentages() {

    try {
      let response = await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/orders/percentage/chains', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setDataPercentages(new Map(Object.entries(response.data)));
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        console.log("Unauthorized")
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  useEffect(() => {
    getPercentages();
  }, []);

  // Transform the map into arrays for labels and data
  const labels = dataPercentages && Array.from(dataPercentages.keys());
  const data = dataPercentages && Array.from(dataPercentages.values()).map(value => value);
  const totalOrders = dataPercentages && Array.from(dataPercentages.values()).map(value => value).reduce((a, b) => a + b, 0);

  // Set up chart options and data
  const pieChartData = {
    series: data,
    options: {
      chart: {
        type: 'donut'
      },
      labels: labels,
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  };

  return (
    <Card alignItems="center" flexDirection="column" w="100%" pb={"75px"}>
      <Flex justify="space-between" align="center" px="10px" pt="5px" pb="5px" w="100%">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Chain distribution
        </Text>
        {/*<Flex flexDirection="column" align="start" me="20px">*/}
        {/*  <Select*/}
        {/*    fontSize="sm"*/}
        {/*    variant="subtle"*/}
        {/*    defaultValue="monthly"*/}
        {/*    width="unset"*/}
        {/*    fontWeight="700"*/}
        {/*  >*/}
        {/*    <option value="all">All</option>*/}
        {/*    <option value="daily">Daily</option>*/}
        {/*    <option value="monthly">Monthly</option>*/}
        {/*    <option value="yearly">Yearly</option>*/}
        {/*  </Select>*/}
        {/*</Flex>*/}

      </Flex>
      <Box mt="auto">
        {dataPercentages &&
            <Chart
                options={pieChartData.options as ApexOptions}
                series={pieChartData.series}
                type="pie"
                width="450"
            />
        }
      </Box>
    </Card>


  )
    ;
};

export default PercentagesPerToken;
