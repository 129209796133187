// Chakra imports
import {Box, Collapse, Flex, Icon, IconButton, Text, useColorModeValue, useDisclosure} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import {MdCheck, MdMoreHoriz} from 'react-icons/md';
import React from "react";

export default function ApiIntegrationSteps(props: { [x: string]: any }) {
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const bgBar = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const bg = useColorModeValue('secondaryGray.300', 'navy.700');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'secondaryGray.500');
  const textColorTertiary = useColorModeValue('secondaryGray.600', 'white');
  const {...rest} = props;

  const {isOpen: isOpenPaymentLink, onToggle: onTogglePaymentLink} = useDisclosure()
  const {isOpen: isOpenFillForm, onToggle: onToggleFillForm} = useDisclosure()
  const {isOpen: isOpenShareLink, onToggle: onToggleShareLink} = useDisclosure()

  return (
    <Card {...rest} maxH='max-content'>
      <Text fontSize='lg' fontWeight='700' mb='16px'>
        Are you a developer? Easily integrate with our API
      </Text>

      <Text fontSize='md' fontWeight='400' mb='50px'>
        You’ll learn to have a powerful Instagram account setup for your Business or personal that you can build
        your brand and convert your followers into paying customers, how to attract 20,000 real followers to
        your account and how to convert your new Instagram followers to long-term loyal paying customers who
        love your business!
      </Text>

      <Flex mb='25px' align='center' cursor='pointer'>
        <Box w='30px' me='12px' mr={10}>
          <IconButton
            onClick={onTogglePaymentLink}
            isRound={true}
            variant='solid'
            color={'white'}
            bg={'green.500'}
            aria-label='Done'
            fontSize='20px'
            icon={<span>{1}</span>}
          />
        </Box>
        <Text color={textColorTertiary} fontWeight='500' fontSize='md' me='5px' onClick={onTogglePaymentLink}>
          Create a new payment link
        </Text>
        <Icon as={MdMoreHoriz} h='18px' w='18px' color={textColorTertiary}/>
      </Flex>

      {/*HIDDEN TEXT EXPLANATION FOR 1) Create a link and start accepting Crypto Payments into your wallet.*/}
      <Flex mb='25px' align='center'>
        <Box w={"100%"}>
          <Collapse in={isOpenPaymentLink} animateOpacity>
            <Box
              p='40px'
              color='gray.600'
              mt='4'
              bg='green.50'
              rounded='md'
              shadow='md'
            >
              Explanation for Create a link and start accepting Crypto Payments into your wallet.
            </Box>
          </Collapse>
        </Box>
      </Flex>

      <Flex mb='25px' align='center' cursor='pointer'>
        <Box w='30px' me='12px' mr={10}>
          <IconButton
            onClick={onToggleFillForm}
            isRound={true}
            variant='solid'
            color={'white'}
            bg={'green.500'}
            aria-label='Done'
            fontSize='20px'
            icon={<span>{2}</span>}
          />
        </Box>
        <Text color={textColorTertiary} fontWeight='500' fontSize='md' me='5px' onClick={onToggleFillForm}>
          Fill the payment form
        </Text>
        <Icon as={MdMoreHoriz} h='18px' w='18px' color={textColorTertiary}/>
      </Flex>

      {/*HIDDEN TEXT EXPLANATION FOR 2) Fill the payment form*/}
      <Flex mb='25px' align='center'>
        <Box w={"100%"}>
          <Collapse in={isOpenFillForm} animateOpacity>
            <Box
              p='40px'
              color='gray.600'
              mt='4'
              bg='green.50'
              rounded='md'
              shadow='md'
            >
              Explanation for Fill the payment form
            </Box>
          </Collapse>
        </Box>
      </Flex>


      <Flex mb='25px' align='center' cursor='pointer'>
        <Box w='30px' me='12px' mr={10}>
          <IconButton
            onClick={onToggleShareLink}
            isRound={true}
            variant='solid'
            color={'white'}
            bg={'green.500'}
            aria-label='Done'
            fontSize='20px'
            icon={<span>{3}</span>}
          />
        </Box>
        <Text color={textColorTertiary} fontWeight='500' fontSize='md' me='5px' onClick={onToggleShareLink}>
          Share your link
        </Text>
        <Icon as={MdMoreHoriz} h='18px' w='18px' color={textColorTertiary}/>
      </Flex>


      {/*HIDDEN TEXT EXPLANATION FOR 3) Share your link*/}
      <Flex mb='25px' align='center'>
        <Box w={"100%"}>
          <Collapse in={isOpenShareLink} animateOpacity>
            <Box
              p='40px'
              color='gray.600'
              mt='4'
              bg='green.50'
              rounded='md'
              shadow='md'
            >
              Explanation for Share your link
            </Box>
          </Collapse>
        </Box>
      </Flex>

    </Card>
  );
}
