import React, { useState } from 'react';
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure, CloseButton
} from '@chakra-ui/react';

// Define a type for the props expected by the ImagePreview component
type ImagePreviewProps = {
  src: string;
};

const ImagePreview: React.FC<ImagePreviewProps> = ({ src }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // No need for useState here, as the src prop is passed directly to the Image component

  return (
    <>
      <Image
        src={src}
        onClick={onOpen}
        cursor="pointer"
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg='blackAlpha.300'
                      backdropFilter='blur(10px)' />
        <ModalContent maxWidth="65%" maxHeight="90%">
          <ModalBody display="flex" justifyContent="center" alignItems="center" p={4}>
            <Image src={src} maxW="100%" maxH="100%" objectFit="contain" />
            <CloseButton position="absolute" top="4" right="4" onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImagePreview;
