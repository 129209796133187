import {GroupedOption, otherCurrencies, popularCurrencies} from "./getpaid/variables/currencies";

export interface PaymentSession {
  sessionId?: string;
  merchantId: string;
  orderId: string;
  concept: string
  recipient: string
  sessionType: string;
  amount: number;
  minAmount?: number;
  editable?: boolean;
  currency: string,
  template: string
  paymentLink: string,
  createdDate: string
}

export const groupedOptions: readonly GroupedOption[] = [
  {
    label: 'Popular',
    options: popularCurrencies,
  },
  {
    label: 'Others',
    options: otherCurrencies,
  },
];

export interface TokenMetadata {
  ticker: string,
  apiId: string,
  iconUrl: string
}

export interface TokenPrices {
  [key: string]: {
    usd: number;
  };
}