import React, {ChangeEvent, useEffect, useState} from 'react';
// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Code,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton, Image,
  Link,
  ListItem,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  useColorModeValue,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import {MdKeyboardArrowLeft} from "react-icons/md";
import {FaCheckCircle, FaCopy, FaExternalLinkAlt} from "react-icons/fa";
import InputField from "../../../../components/fields/InputField";
import {useForm} from "react-hook-form";
import {Chain, PaymentSettings, Token} from "../../../../types/payment.types";
import {chainsDisplayNames} from "../../../../variables/constants";
import axios, {AxiosError} from "axios";
import {generateAPIKey, getAPIKeys, getMerchantPaymentSettings, saveSiteUrl} from "../../../../services/useAPICaller";
import spcPluginSteps from 'assets/img/starthere/plugins/plugin_steps.png';
import spcPluginStepActivate from 'assets/img/starthere/plugins/step_activate.png';
import spcPluginStepConfig1 from 'assets/img/starthere/plugins/step_configure1.png';
import spcPluginStepConfig2 from 'assets/img/starthere/plugins/step_configure2.png';
import spcPluginStepConfig3 from 'assets/img/starthere/plugins/step_configure3.png';
import ImagePreview from "./ImagePreview";
import {WarningTwoIcon} from "@chakra-ui/icons";

// Assets

interface CreatePaymentLinkProps {
  onBackClick: () => void;
  activeBulletsProp?: {
    product: boolean;
    media: boolean;
    notifications: boolean;
  };
  fromWoocommerce: string;
}


export default function Plugins({onBackClick, activeBulletsProp, fromWoocommerce}: CreatePaymentLinkProps) {

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.900';
  const [activeBullets, setActiveBullets] = useState(activeBulletsProp || {
    product: false,
    media: false,
    notifications: false,
  });
  // const [activeBullets, setActiveBullets] = useState({
  //   product: true,
  //   media: false,
  //   notifications: false,
  // });

  const {isOpen: isOpenPaymentLink, onToggle: onTogglePaymentLink} = useDisclosure()
  const {isOpen: isOpenInstallSpcPlugin, onToggle: onToggleInstallSpcPlugin} = useDisclosure()
  const {isOpen: isOpenActivateSpcPlugin, onToggle: onToggleActivateSpcPlugin} = useDisclosure()
  const {isOpen: isOpenEnableSpcPlugin, onToggle: onToggleEnableSpcPlugin} = useDisclosure()
  const {isOpen: isOpenInsertApiKey, onToggle: onToggleInsertApiKey} = useDisclosure()

  const productTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const mediaTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const notificationsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const theme = useTheme();
  //eslint-disable-next-line
  const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
  //eslint-disable-next-line
  const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
  const brand = useColorModeValue(lineColor, lineColorDark);
  const [paymentSettingsBackend, setPaymentSettingsBackend] = useState<PaymentSettings>();
  const [apiKey, setApiKey] = useState('');

  // Notifications connect behavior
  const [authorizedSiteUrl, setAuthorizedSiteUrl] = useState<string>();
  const [isAuthorizedSite, setIsAuthorizedSite] = useState<Boolean>();

  const [businessUrl, setBusinessUrl] = useState<string>();
  const [isAuthRedirecting, setIsAuthRedirecting] = useState<boolean>();
  const [isAuthApiCallInProgress, setIsAuthApiCallInProgress] = useState<boolean>(false);
  const [areBusinessUrlAndSiteUrlEquals, setAreBusinessUrlAndSiteUrlEquals] = useState<boolean>(true);
  const [userId, setUserId] = useState<string>();

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    getValues
  } = useForm()

  const okToast = useToast({
    position: 'top',
    title: 'Connected successfully to your WooCommerce store!',
    status: 'success',
    duration: 6000,
  })

  const koToast = useToast({
    position: 'top',
    title: 'Unable to connect with your WooCommerce store. Try again later.',
    status: 'error',
    duration: 6000,
  })

  const handleSiteUrlChange = (event: ChangeEvent<HTMLInputElement>) => {

    const {value} = event.target;
    setAuthorizedSiteUrl(value);
    if (value === paymentSettingsBackend.businessUrl) {
      setAreBusinessUrlAndSiteUrlEquals(true);
    } else {
      setAreBusinessUrlAndSiteUrlEquals(false);
    }
  };

  function onSubmitPlugins(values: any) {
    return new Promise((resolve) => {
      let paymentSettingToUpdate = {} as PaymentSettings
      let chainsToUpdate = new Array<Chain>()
      //Process chains
      Object.keys(values).forEach(function (key) {
        if (key.startsWith("chain_")) {
          // Process Chains
          let chain = {} as Chain
          let strTokens = key.split("_");
          chain.name = strTokens.at(1);
          chain.network = strTokens.at(2);
          chain.id = Number(strTokens.at(3));

          if (key.startsWith("chain_")) {
            chain.active = values[key];
          }
          if (key.startsWith("publicAddress_")) {
            chain.publicAddress = values[key];
          }

          //Process Tokens
          chain.tokens = new Array<Token>();
          Object.keys(values).forEach(function (key) {
            if (key.startsWith("token_") && key.indexOf(chain.name + "_" + chain.network) !== -1) {
              let token = {} as Token
              let strTokens = key.split("_");
              token.symbol = strTokens.at(3).toUpperCase();
              token.active = values[key];
              chain.tokens.push(token);
            }
          });
          chainsToUpdate.push(chain);
        }
      });

      chainsToUpdate.forEach((chain) => {
        chain.publicAddress = values["publicAddress_" + chain.name + "_" + chain.network];
      })

      paymentSettingToUpdate.businessUrl = values.pluginsBusinessUrl;
      paymentSettingToUpdate.businessDescription = values.businessDescription;
      paymentSettingToUpdate.merchantName = values.merchantName;
      paymentSettingToUpdate.chains = chainsToUpdate;
      paymentSettingToUpdate.merchantLogoUrl = values.merchantLogoUrl;
      updateMerchantPaymentSettings(paymentSettingToUpdate);
    })
  }

  function updateMerchantPaymentSettings(paymentSettingToUpdate: PaymentSettings) {

    try {
      axios.put(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', JSON.parse(JSON.stringify(paymentSettingToUpdate)), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      mediaTab.current.click();
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function joinUrls(baseUrl: string, path: string) {
    return `${baseUrl.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`;
  }

  function handleCopyApiKey() {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey)
    }
  }

  async function getAuthorizedSiteStatus(): Promise<boolean> {
    setIsAuthApiCallInProgress(true);
    try {
      let response = await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/woocommerce/site/status', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      //return Promise.resolve(200 === response.status); Testing purposes
      setAuthorizedSiteUrl(response.data.siteUrl);
      setIsAuthorizedSite(200 === response.status);
      setIsAuthApiCallInProgress(false);
      return 200 === response.status;
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
      return false;
    }
  }

  function handleWoocommerceAuthorization() {

    setIsAuthRedirecting(true);
    authorizedSiteUrl && saveSiteUrl(authorizedSiteUrl);
    const returnUrl = encodeURIComponent('https://dashboard.sprintcheckout.com/#/admin/start-here?woocommerce=true');
    window.location.replace(`${authorizedSiteUrl ? authorizedSiteUrl : businessUrl}/wc-auth/v1/authorize?callback_url=${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/payment_settings/woocommerce/authorization&app_name=Sprintcheckout&user_id=${userId}&scope=read_write&return_url=${returnUrl}`);
    //const woocommerceUrl = `${authorizedSiteUrl ? authorizedSiteUrl : businessUrl}/wc-auth/v1/authorize?callback_url=${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/payment_settings/woocommerce/authorization&app_name=Sprintcheckout&user_id=${userId}&scope=read_write`
    //const woocommerceUrl = `${authorizedSiteUrl ? authorizedSiteUrl : businessUrl}/wc-auth/v1/authorize?callback_url=${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/payment_settings/woocommerce/authorization&app_name=Sprintcheckout&user_id=${userId}&scope=read_write&return_url=https://dashboard.sprintcheckout.com/#/admin/start-here?woocommerce=true`
    // const windowTitle = "Plugin connect";
    // const popupWidth = 1200;
    // const popupHeight = 800;
    // openPopupWindow(woocommerceUrl, windowTitle, popupWidth, popupHeight, () => {
    //   const res = getAuthorizedSiteStatus();
    //   res.then((isAuthorized) => {
    //     if (isAuthorized) {
    //       okToast();
    //     } else {
    //       koToast();
    //     }
    //   });
    // });
  }

  function openPopupWindow(url: string, title: string, width: number, height: number, onClose?: () => void) {
    // Calculate the position for the new window to be centered
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    // Open a new window
    const newWindow = window.open(url, title, `width=${width},height=${height},top=${top},left=${left}`);

    // Focus on the new window if it was successfully opened
    if (newWindow) newWindow.focus();

    const pollTimer = setInterval(() => {
      console.log("polling");
      if (newWindow.closed) {
        clearInterval(pollTimer);
        if (onClose) onClose(); // Call the onClose callback when the popup is closed
      }
    }, 200);
  }

  useEffect(() => {

    getMerchantPaymentSettings(setPaymentSettingsBackend).then(paymentSettings => {
      if (paymentSettings) {
        let castPaymentSettings: PaymentSettings = JSON.parse(JSON.stringify(paymentSettings.data));
        setPaymentSettingsBackend(castPaymentSettings);
        setUserId(paymentSettings.data.mail);
        setBusinessUrl(paymentSettings.data.businessUrl);
        setAuthorizedSiteUrl(paymentSettings.data.businessUrl);
      }
    });
    getAPIKeys().then(keys => {
      if (keys && keys.data.length > 0) {
        setApiKey(keys.data[0]);
      } else {
        generateAPIKey(setApiKey);
      }
    });

    const checkAuthorization = async () => {
      if (fromWoocommerce && notificationsTab && notificationsTab.current) {
        notificationsTab.current.click();
        const isAuthorized = await getAuthorizedSiteStatus();
        isAuthorized ? okToast() : koToast();
        setIsAuthApiCallInProgress(false);
      }
    };

    checkAuthorization();

  }, [fromWoocommerce, notificationsTab.current]);

  return (
    <>
      {!paymentSettingsBackend &&
          <Flex align='center' w='100%' justify='center' mt={'20%'}>
              <Spinner thickness='6px' speed='0.65s' emptyColor='gray.200' color='blue.500' boxSize={24} mb={20}/>
          </Flex>
      }

      {paymentSettingsBackend &&
          <Flex
              direction="column"
              minH="100vh"
              align="center"
              pt={{sm: '125px', lg: '75px'}}
              position="relative"
          >
              <Box
                  h="45vh"
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  position="absolute"
                  w="100%"
                  borderRadius="20px">
                  <Flex align='start' w='100%' justify='flex-start' mt={5} ml={5}>
                      <Flex align='center' ml={3} onClick={onBackClick} cursor={'pointer'}>
                          <Icon as={MdKeyboardArrowLeft} h='18px' w='18px' color={'white'}/>
                          <Text color={'white'} fontSize={18} fontWeight={'bold'}>Back</Text>
                      </Flex>
                  </Flex>
              </Box>

              <Tabs
                  variant="unstyled"
                  mt={{base: '60px', md: '165px'}}
                  zIndex="0"
                  display="flex"
                  flexDirection="column"
              >
                  <TabList
                      display="flex"
                      alignItems="center"
                      alignSelf="center"
                      justifySelf="center"
                  >
                      <Tab
                          _focus={{border: '0px', boxShadow: 'unset'}}
                          ref={productTab}
                          w={{sm: '120px', md: '250px', lg: '300px'}}
                          onClick={() =>
                            setActiveBullets({
                              product: true,
                              media: false,
                              notifications: false,
                            })
                          }
                      >
                          <Flex
                              direction="column"
                              justify="center"
                              align="center"
                              position="relative"
                              _before={{
                                content: "''",
                                width: {sm: '120px', md: '250px', lg: '300px'},
                                height: '3px',
                                bg: activeBullets.media ? 'white' : 'brand.400',
                                left: {sm: '12px', md: '40px'},
                                top: {
                                  sm: activeBullets.product ? '6px' : '4px',
                                  md: null,
                                },
                                position: 'absolute',
                                bottom: activeBullets.product ? '40px' : '38px',

                                transition: 'all .3s ease',
                              }}
                          >
                              <Box
                                  zIndex="1"
                                  border="2px solid"
                                  borderColor={activeBullets.product ? 'white' : 'brand.400'}
                                  bgGradient="linear(to-b, brand.400, brand.600)"
                                  w="16px"
                                  h="16px"
                                  mb="8px"
                                  borderRadius="50%"
                              />
                              <Text
                                  color={activeBullets.product ? 'white' : 'gray.300'}
                                  fontWeight={activeBullets.product ? 'bold' : 'normal'}
                                  display={{sm: 'none', md: 'block'}}
                              >
                                  Set up
                              </Text>
                          </Flex>
                      </Tab>
                      <Tab
                          _focus={{border: '0px', boxShadow: 'unset'}}
                          ref={mediaTab}
                          w={{sm: '120px', md: '250px', lg: '300px'}}
                          onClick={() =>
                            setActiveBullets({
                              product: true,
                              media: true,
                              notifications: false,
                            })
                          }
                      >
                          <Flex
                              direction="column"
                              justify="center"
                              align="center"
                              position="relative"
                              _before={{
                                content: "''",
                                width: {sm: '120px', md: '250px', lg: '300px'},
                                height: '3px',
                                bg: activeBullets.notifications ? 'white' : 'brand.400',
                                left: {sm: '12px', md: '28px'},
                                top: '6px',
                                position: 'absolute',
                                bottom: activeBullets.media ? '40px' : '38px',

                                transition: 'all .3s ease',
                              }}
                          >
                              <Box
                                  zIndex="1"
                                  border="2px solid"
                                  borderColor={activeBullets.media ? 'white' : 'brand.400'}
                                  bgGradient="linear(to-b, brand.400, brand.600)"
                                  w="16px"
                                  h="16px"
                                  mb="8px"
                                  borderRadius="50%"
                              />
                              <Text
                                  color={activeBullets.media ? 'white' : 'gray.300'}
                                  fontWeight={activeBullets.media ? 'bold' : 'normal'}
                                  display={{sm: 'none', md: 'block'}}
                              >
                                  Get paid
                              </Text>
                          </Flex>
                      </Tab>
                      <Tab
                          _focus={{border: '0px', boxShadow: 'unset'}}
                          ref={notificationsTab}
                          w={{sm: '120px', md: '250px', lg: '300px'}}
                          onClick={() =>
                            setActiveBullets({
                              product: true,
                              media: true,
                              notifications: true,
                            })
                          }
                      >
                          <Flex
                              direction="column"
                              justify="center"
                              align="center"
                              position="relative"
                          >
                              <Box
                                  zIndex="1"
                                  border="2px solid"
                                  borderColor={activeBullets.notifications ? 'white' : 'brand.400'}
                                  bgGradient="linear(to-b, brand.400, brand.600)"
                                  w="16px"
                                  h="16px"
                                  mb="8px"
                                  borderRadius="50%"
                              />
                              <Text
                                  color={activeBullets.notifications ? 'white' : 'gray.300'}
                                  fontWeight={activeBullets.notifications ? 'bold' : 'normal'}
                                  display={{sm: 'none', md: 'block'}}
                              >
                                  Notifications
                              </Text>
                          </Flex>
                      </Tab>
                  </TabList>
                  <TabPanels mt="24px" maxW={{md: '90%', lg: '100%'}} mx="auto">
                    {/***********************************   SET UP WIZARD   **********************************/}
                      <form onSubmit={handleSubmit(onSubmitPlugins)}>

                          <TabPanel
                              w={{sm: '330px', md: '700px', lg: '850px'}}
                              p="0px"
                              mx="auto"
                          >
                              <Card p="30px">
                                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                      Your Business
                                  </Text>
                                  <Flex direction="column" w="100%">
                                      <SimpleGrid columns={{base: 1, md: 2}} gap="20px">
                                          <Stack direction="column" gap="20px">
                                              <InputField
                                                  register={register}
                                                  mb="0px"
                                                  id="merchantName"
                                                  placeholder="eg. Alice Flowers"
                                                  label="Business name"
                                                  defaultValue={paymentSettingsBackend.merchantName}
                                              />
                                              <InputField
                                                  register={register}
                                                  mb="0px"
                                                  id="pluginsBusinessUrl"
                                                  placeholder="eg. https://alice-flowers.com"
                                                  defaultValue={paymentSettingsBackend.businessUrl}
                                                  label="Site URL"
                                              />
                                          </Stack>
                                          <Stack direction="column" gap="20px">
                                              <InputField
                                                  register={register}
                                                  mb="0px"
                                                  id="merchantLogoUrl"
                                                  placeholder="eg. https://via.placeholder.com/your-logo.png"
                                                  label="Logo URL (optional)"
                                                  defaultValue={paymentSettingsBackend.merchantLogoUrl}
                                              />

                                            {/*<TextField*/}
                                            {/*  h="146px"*/}
                                            {/*  mb="0px"*/}
                                            {/*  id="Description"*/}
                                            {/*  placeholder="Short description about the business"*/}
                                            {/*  label="Description"*/}
                                            {/*/>*/}
                                          </Stack>

                                      </SimpleGrid>

                                      <Tabs variant='soft-rounded' colorScheme='blue' mb={5}>
                                          <TabPanels>
                                            {/***********************************  WALLETS TAB  **********************************/}
                                              <TabPanel mt={10} ml={-3}>
                                                {/***********************************  WALLETS TAB CHAIN & PUBLIC ADDRESS   **********************************/}
                                                  <Tabs variant='soft-rounded' colorScheme='green'>
                                                      <TabList>
                                                          <Tab>Mainnet</Tab>
                                                          <Tab>Testnet</Tab>
                                                      </TabList>
                                                      <TabPanels>
                                                        {/***********************************   MAINNET   **********************************/}
                                                          <TabPanel>
                                                            {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                                              [key: string]: any
                                                            }, chain) => {
                                                              if (!acc[chain.name] && chain.network === "mainnet") {
                                                                acc[chain.name] = chain;
                                                              }
                                                              return acc;
                                                            }, {})).map((chain) => (
                                                              <Box mb={2}>

                                                                <Accordion allowToggle outline={"0px solid #e0dddd"}
                                                                           borderStyle={"none"}>
                                                                  <AccordionItem borderStyle={"none"}>
                                                                    <h2>
                                                                      <AccordionButton>
                                                                        <Flex justifySelf={'flex-start'}
                                                                              minW={['1', null, '150px']}>
                                                                          <Switch mr={5} mt={1} colorScheme="brand"
                                                                                  {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                                                  key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                                                  defaultChecked={chain.active}
                                                                          />
                                                                          <Text fontWeight='bold' fontSize='lg'
                                                                                mb='5px'>
                                                                            {chainsDisplayNames[chain.name + "-" + chain.network]}
                                                                          </Text>
                                                                        </Flex>
                                                                        <Box as="span" flex='1' textAlign='left'>
                                                                        </Box>
                                                                        <AccordionIcon/>
                                                                      </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={4}>
                                                                      {chain &&
                                                                          <InputField
                                                                              register={register}
                                                                              id={"publicAddress_" + chain.name + "_" + chain.network}
                                                                              placeholder="eg. 0x0000000000000000000000000000000000000000"
                                                                              label="Your public address"
                                                                              defaultValue={chain.publicAddress}
                                                                          />}


                                                                      <Accordion allowToggle
                                                                                 mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                                                        <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                                                          <h2>
                                                                            <AccordionButton>
                                                                              <Box as="span" flex='1'
                                                                                   textAlign='left'>
                                                                                <Text mb='4px' mr='15px'
                                                                                      style={{whiteSpace: 'nowrap'}}>
                                                                                  Available tokens
                                                                                </Text>
                                                                              </Box>
                                                                              <AccordionIcon/>
                                                                            </AccordionButton>
                                                                          </h2>
                                                                          <AccordionPanel pb={4}>
                                                                            <Table variant="simple">
                                                                              <Tbody>
                                                                                {chain.tokens && chain.tokens.map((token: Token) => {
                                                                                  return <Tr>
                                                                                    <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                                                    <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>
                                                                                    <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                                                key={chain.name + chain.network + token.symbol}
                                                                                                colorScheme="brand"
                                                                                                defaultChecked={token.active}/></Td>
                                                                                  </Tr>;
                                                                                })}
                                                                              </Tbody>
                                                                            </Table>
                                                                          </AccordionPanel>
                                                                        </AccordionItem>
                                                                      </Accordion>
                                                                    </AccordionPanel>
                                                                  </AccordionItem>
                                                                </Accordion>
                                                              </Box>
                                                            ))}
                                                          </TabPanel>
                                                        {/***********************************   TESTNET   **********************************/}
                                                          <TabPanel>
                                                            {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                                              [key: string]: any
                                                            }, chain) => {
                                                              if (!acc[chain.name] && chain.network !== "mainnet") {
                                                                acc[chain.name] = chain;
                                                              }
                                                              return acc;
                                                            }, {})).map((chain) => (
                                                              <Box mb={2}>

                                                                <Accordion allowToggle outline={"0px solid #e0dddd"}
                                                                           borderStyle={"none"}>
                                                                  <AccordionItem borderStyle={"none"}>
                                                                    <h2>
                                                                      <AccordionButton>
                                                                        <Flex justifySelf={'flex-start'}
                                                                              minW={['1', null, '150px']}>
                                                                          <Switch mr={5} mt={1} colorScheme="brand"
                                                                                  {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                                                  key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                                                  defaultChecked={chain.active}
                                                                          />
                                                                          <Text fontWeight='bold' fontSize='lg'
                                                                                mb='5px'>
                                                                            {chainsDisplayNames[chain.name + "-" + chain.network]}
                                                                          </Text>
                                                                        </Flex>
                                                                        <Box as="span" flex='1' textAlign='left'>
                                                                        </Box>
                                                                        <AccordionIcon/>
                                                                      </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={4}>
                                                                      {chain &&
                                                                          <InputField
                                                                              register={register}
                                                                              id={"publicAddress_" + chain.name + "_" + chain.network}
                                                                              placeholder="eg. 0x0000000000000000000000000000000000000000"
                                                                              label="Your public address"
                                                                              defaultValue={chain.publicAddress}
                                                                          />}

                                                                      <Accordion allowToggle
                                                                                 mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                                                        <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                                                          <h2>
                                                                            <AccordionButton>
                                                                              <Box as="span" flex='1'
                                                                                   textAlign='left'>
                                                                                <Text mb='4px' mr='15px'
                                                                                      style={{whiteSpace: 'nowrap'}}>
                                                                                  Available tokens
                                                                                </Text>
                                                                              </Box>
                                                                              <AccordionIcon/>
                                                                            </AccordionButton>
                                                                          </h2>
                                                                          <AccordionPanel pb={4}>
                                                                            <Table variant="simple">
                                                                              <Tbody>
                                                                                {chain.tokens && chain.tokens.map((token: Token) => {
                                                                                  return <Tr>
                                                                                    <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                                                    <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>
                                                                                    <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                                                key={chain.name + chain.network + token.symbol}
                                                                                                colorScheme="brand"
                                                                                                defaultChecked={token.active}/></Td>
                                                                                  </Tr>;
                                                                                })}
                                                                              </Tbody>
                                                                            </Table>
                                                                          </AccordionPanel>
                                                                        </AccordionItem>
                                                                      </Accordion>
                                                                    </AccordionPanel>
                                                                  </AccordionItem>
                                                                </Accordion>
                                                              </Box>
                                                            ))}
                                                          </TabPanel>
                                                      </TabPanels>
                                                  </Tabs>
                                              </TabPanel>
                                          </TabPanels>
                                      </Tabs>

                                      <Flex justify="space-between" mt="24px">
                                          <Button
                                              variant="brand"
                                              fontSize="sm"
                                              borderRadius="16px"
                                              w={{base: '128px', md: '148px'}}
                                              h="46px"
                                              ms="auto"
                                              onClick={handleSubmit(onSubmitPlugins)}
                                          >
                                              Continue
                                          </Button>
                                      </Flex>
                                  </Flex>
                              </Card>
                          </TabPanel>
                      </form>
                    {/***********************************   INSTALL PLUGIN WIZARD   **********************************/}

                      <TabPanel
                          w={{sm: '330px', md: '700px', lg: '850px'}}
                          p="0px"
                          mx="auto"
                      >
                          <Card p="30px">
                              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                  Get paid
                              </Text>

                            {/******************************************   1) INSTALL OUR PLUGIN **********************************/}

                              <Accordion allowToggle>
                                  <AccordionItem>
                                      <h2>
                                          <AccordionButton py={4}>
                                              <Flex align="center" flex="1" textAlign="left">
                                                  <IconButton
                                                      size="xs"
                                                      isRound={true}
                                                      variant="solid"
                                                      color="white"
                                                      bg="#422AFB"
                                                      aria-label="One"
                                                      fontSize="15px"
                                                      icon={<span>{1}</span>}
                                                      mr={3}
                                                  />
                                                  <Box as="span" fontWeight="bold" fontSize="lg">
                                                      Install the plugin
                                                  </Box>
                                              </Flex>
                                              <AccordionIcon/>
                                          </AccordionButton>
                                      </h2>
                                      <AccordionPanel pb={4}>
                                          <ImagePreview src={spcPluginSteps}/>
                                          <Flex align="center" mt={4} mb={2}>
                                            {/*<Image maxW={'32px'} minW={'32px'} src={spcPluginStep1} mr={2}/>*/}
                                              <Text>1) Go to <Box as="span"
                                                                  fontWeight="bold">Plugins</Box> section</Text>
                                            {paymentSettingsBackend.businessUrl &&
                                                <>
                                                    <Icon ml={4} as={FaExternalLinkAlt}/>
                                                    <Link ml={1} textDecoration={"underline"} isExternal
                                                          href={joinUrls(paymentSettingsBackend.businessUrl, '/wp-admin/plugins.php')}>
                                                        ({paymentSettingsBackend.merchantName} plugins)
                                                    </Link>
                                                </>
                                            }
                                          </Flex>
                                          <Flex align="center" mt={4} mb={2}>
                                            {/*<Image maxW={'32px'} minW={'32px'} src={spcPluginStep2} mr={2}/>*/}
                                              <Text>2) Head to <Box as="span" fontWeight="bold">Add New</Box></Text>
                                          </Flex>
                                          <Flex align="center" mt={4} mb={2}>
                                            {/*<Image maxW={'32px'} minW={'32px'} src={spcPluginStep3} mr={2}/>*/}
                                              <Text>3) Search for <Box as="span"
                                                                       fontWeight="bold">Sprintcheckout</Box></Text>
                                          </Flex>
                                          <Flex align="center" mt={4} mb={2}>
                                            {/*<Image maxW={'32px'} minW={'32px'} src={spcPluginStep4} mr={2}/>*/}
                                              <Text>4) Click <Box as="span" fontWeight="bold">Install now</Box></Text>
                                          </Flex>
                                      </AccordionPanel>
                                  </AccordionItem>

                                {/******************************************   2) ACTIVATE IT **********************************/}
                                  <AccordionItem>
                                      <h2>
                                          <AccordionButton py={4}>
                                              <Flex align="center" flex="1" textAlign="left">
                                                  <IconButton
                                                      size="xs"
                                                      isRound={true}
                                                      variant="solid"
                                                      color="white"
                                                      bg="#422AFB"
                                                      aria-label="One"
                                                      fontSize="15px"
                                                      icon={<span>{2}</span>}
                                                      mr={3}
                                                  />
                                                  <Box as="span" fontWeight="bold" fontSize="lg">
                                                      Activate the plugin
                                                  </Box>
                                              </Flex>
                                              <AccordionIcon/>
                                          </AccordionButton>
                                      </h2>
                                      <AccordionPanel pb={4}>
                                          <Flex mb='25px' align='center'>
                                              <Box w={"100%"}>
                                                  <ImagePreview src={spcPluginStepActivate}/>
                                                  <Flex align="center" mt={4} mb={2}>
                                                      <Text>Click on <Box as="span" fontWeight="bold"
                                                                          color={'#5b99d0'}>Activate</Box></Text>
                                                  </Flex>
                                              </Box>
                                          </Flex>
                                      </AccordionPanel>
                                  </AccordionItem>
                                {/******************************************   3) ENABLE IT **********************************/}
                                  <AccordionItem>
                                      <h2>
                                          <AccordionButton py={4}>
                                              <Flex align="center" flex="1" textAlign="left">
                                                  <IconButton
                                                      size="xs"
                                                      isRound={true}
                                                      variant="solid"
                                                      color="white"
                                                      bg="#422AFB"
                                                      aria-label="One"
                                                      fontSize="15px"
                                                      icon={<span>{3}</span>}
                                                      mr={3}
                                                  />
                                                  <Box as="span" fontWeight="bold" fontSize="lg">
                                                      Enable the plugin
                                                  </Box>
                                              </Flex>
                                              <AccordionIcon/>
                                          </AccordionButton>
                                      </h2>
                                      <AccordionPanel pb={4}>
                                          <Flex mb='25px' align='center'>
                                              <Box w={"100%"}>
                                                  <ImagePreview src={spcPluginStepConfig1}/>
                                                  <Flex align="center" mt={4} mb={2}>
                                                      <Text>1) Head to <Box as="span"
                                                                            fontWeight="bold">WooCommerce</Box></Text>
                                                  </Flex>
                                                  <Flex align="center" mt={4} mb={2}>
                                                      <Text>2) Navigate to <Box as="span"
                                                                                fontWeight="bold">Settings</Box></Text>
                                                  </Flex>
                                                  <Flex align="center" mt={4} mb={2}>
                                                      <Text>3) Click on <Box as="span"
                                                                             fontWeight="bold">Payments</Box> tab</Text>
                                                  </Flex>

                                                  <ImagePreview src={spcPluginStepConfig2}/>
                                                {/*<Flex align="center" mt={4} mb={2}>*/}
                                                {/*<Image maxW={'32px'} minW={'32px'} src={spcPluginSwitch} mr={2}/>*/}
                                                {/*    <Text>Enable the <Box as="span" fontWeight="bold">Sprintcheckout Payment</Box> switch</Text>*/}
                                                {/*</Flex>*/}
                                                  <Flex align="center" mt={4} mb={2}>
                                                      <UnorderedList>
                                                          <ListItem>Enable the Sprintcheckout payment
                                                              method</ListItem>
                                                          <ListItem>Click <Box as="span" fontWeight="bold"
                                                                               color={'#5b99d0'}>Manage</Box></ListItem>
                                                      </UnorderedList>
                                                    {/*<Image maxW={'32px'} minW={'32px'} src={spcPluginManageButton} mr={2}/>*/}
                                                    {/*<Text>Click on <Box as="span" fontWeight="bold" color={'#5b99d0'}>Manage</Box></Text>*/}
                                                  </Flex>
                                              </Box>
                                          </Flex>
                                      </AccordionPanel>
                                  </AccordionItem>
                                {/******************************************   3) INSERT YOUR API KEY **********************************/}
                                  <AccordionItem>
                                      <h2>
                                          <AccordionButton py={4}>
                                              <Flex align="center" flex="1" textAlign="left">
                                                  <IconButton
                                                      size="xs"
                                                      isRound={true}
                                                      variant="solid"
                                                      color="white"
                                                      bg="#422AFB"
                                                      aria-label="One"
                                                      fontSize="15px"
                                                      icon={<span>{4}</span>}
                                                      mr={3}
                                                  />
                                                  <Box as="span" fontWeight="bold" fontSize="lg">
                                                      Insert your API Key
                                                  </Box>
                                              </Flex>
                                              <AccordionIcon/>
                                          </AccordionButton>
                                      </h2>
                                      <AccordionPanel pb={4}>
                                          <ImagePreview src={spcPluginStepConfig3}/>
                                          <UnorderedList>
                                              <ListItem>
                                                  <Flex align="center" mt={4} mb={2}>
                                                      <Text>Copy and paste your API key:</Text>
                                                      <Code ml={2} colorScheme='green' children={apiKey}/>
                                                      <Box ml={2} cursor={'pointer'}
                                                           onClick={handleCopyApiKey}><FaCopy
                                                          cursor={'pointer'}/></Box>
                                                  </Flex>
                                              </ListItem>
                                              <ListItem>
                                                  <Flex align="center" mt={2}>
                                                      <Text>Click <Box as="span" fontWeight="bold" color={'#5b99d0'}>Save
                                                          changes</Box></Text>
                                                  </Flex>
                                              </ListItem>
                                          </UnorderedList>

                                          <Alert status='info' mt={5}>
                                              <AlertIcon/>
                                              The API Key is unique to your account. Create and revoke API keys on the
                                              <Link onClick={() => window.location.replace("/#/admin/get-paid")}
                                                    as="span" fontWeight='medium' mx="1"> Get paid</Link> section
                                          </Alert>
                                          <Flex mb='25px' align='center'>
                                              <Box w={"100%"}>
                                                  <Collapse in={isOpenInsertApiKey} animateOpacity>
                                                      <ImagePreview src={spcPluginStepConfig3}/>
                                                  </Collapse>
                                              </Box>
                                          </Flex>
                                      </AccordionPanel>
                                  </AccordionItem>
                              </Accordion>

                              <Flex justify="space-between" mt="24px">
                                  <Button
                                      variant="light"
                                      fontSize="sm"
                                      borderRadius="16px"
                                      w={{base: '128px', md: '148px'}}
                                      h="46px"
                                      onClick={() => productTab.current.click()}
                                  >
                                      Prev
                                  </Button>
                                  <Button
                                      variant="brand"
                                      fontSize="sm"
                                      borderRadius="16px"
                                      w={{base: '128px', md: '148px'}}
                                      h="46px"
                                      ms="auto"
                                      onClick={() => notificationsTab.current.click()}
                                  >
                                      Continue
                                  </Button>
                              </Flex>

                          </Card>
                      </TabPanel>
                    {/***********************************   NOTIFICATIONS WIZARD   **********************************/}
                      <TabPanel
                          w={{sm: '330px', md: '700px', lg: '850px'}}
                          p="0px"
                          mx="auto"
                      >
                          <Card p="30px">
                              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="4">
                                  Notifications
                              </Text>
                              <Text fontSize='md' color={textColorSecondary} mb={10}>
                                  Connect your{' '}
                                  <Text as='span' fontWeight='bold'>
                                      WooCommerce
                                  </Text>{' '}
                                  store. Your orders will be updated once the payment is confirmed:
                              </Text>

                              <InputField
                                  mb={2}
                                  register={register}
                                  disabled={isAuthorizedSite}
                                  onChange={handleSiteUrlChange}
                                  id="businessUrl"
                                  placeholder="eg. https://alice-flowers.com"
                                  label="Site URL"
                                  value={authorizedSiteUrl}
                                  type="text"
                              />
                            {!areBusinessUrlAndSiteUrlEquals &&
                                <HStack align="center" ml={2}>
                                    <Icon as={WarningTwoIcon} color="orange.400"/>
                                    <Text mt={1} color="orange.400">
                                        Your business Site URL will be updated
                                    </Text>
                                </HStack>
                            }
                              <Flex align="end" justify="end" mb={10}>
                                {!isAuthorizedSite && !isAuthApiCallInProgress && <Button
                                    variant="outline"
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{base: '128px', md: '148px'}}
                                    h="46px"
                                    isLoading={isAuthRedirecting}
                                    loadingText={'Redirecting...'}
                                    onClick={handleWoocommerceAuthorization}
                                >
                                    Connect
                                </Button>
                                }
                                {isAuthApiCallInProgress && <Button
                                    variant="outline"
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{base: '128px', md: '148px'}}
                                    h="46px"
                                    isLoading={isAuthApiCallInProgress}
                                    loadingText={'Checking...'}
                                >
                                    Checking...
                                </Button>
                                }
                                {isAuthorizedSite && !isAuthApiCallInProgress &&
                                    <Button
                                        variant="outline"
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{base: '128px', md: '148px'}}
                                        h="46px"
                                        disabled={true}
                                    > Connected <Icon ml={2} as={FaCheckCircle} width='15px' height='15px'
                                                      color='green.500'/></Button>
                                }
                              </Flex>

                              <Flex w="100%" justifyContent="space-between">
                                  <Button
                                      variant="light"
                                      fontSize="sm"
                                      borderRadius="16px"
                                      w={{base: '128px', md: '148px'}}
                                      h="46px"
                                      onClick={() => mediaTab.current.click()}
                                  >
                                      Prev
                                  </Button>
                                  <Button
                                      variant="brand"
                                      fontSize="sm"
                                      borderRadius="16px"
                                      w={{base: '128px', md: '148px'}}
                                      h="46px"
                                      onClick={onBackClick}
                                  >
                                      Done
                                  </Button>
                              </Flex>

                          </Card>
                      </TabPanel>
                  </TabPanels>
              </Tabs>
          </Flex>
      }
    </>

  );
}
