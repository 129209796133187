/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect, useState} from 'react';

// Chakra imports
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex, Grid,
  Heading,
  Image, Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue, useDisclosure
} from '@chakra-ui/react';

import Banner from "../notifications/components/Banner";
import Nft1 from 'assets/img/nfts/Nft1.png';
import PayLink from 'assets/img/starthere/paymentLink.png';
import WooPlugin from 'assets/img/starthere/woocommerce.png';
import ApiInt from 'assets/img/starthere/api.png';


import Plugins from "./components/Plugins";
import ApiIntegration from "./components/ApiIntegration";
import CreatePaymentLink from "./components/CreatePaymentLink";

export default function StartHere() {

  const textColorTertiary = useColorModeValue('secondaryGray.600', 'secondaryGray.500');
  const [showStartHereCards, setShowStartHereCards] = useState<boolean>(true);
  const [showPaymentLink, setShowPaymentLink] = useState<boolean>(false);
  const [showPlugins, setShowPlugins] = useState<boolean>(false);
  const [showApiIntegration, setShowApiIntegration] = useState<boolean>(false);
  const [fromWoocommerce, setFromWoocommerce] = useState<string>('');


  function handleShow(section: string) {
    setShowStartHereCards(false);
    if (section === 'link') {
      setShowPaymentLink(true);
    }
    if (section === 'plugin') {
      setShowPlugins(true);
    }
    if (section === 'integration') {
      setShowApiIntegration(true);
    }
  }

  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    notifications: false,
  });

  useEffect(() => {
    const hash = window.location.hash;
    const queryString = hash.substring(hash.indexOf('?') + 1);
    const params = new URLSearchParams(queryString);
    const fromWoocommerce = params.get('woocommerce');
    setFromWoocommerce(fromWoocommerce);
    if (fromWoocommerce === 'true') {
      handleShow('plugin');
      setActiveBullets({
        product: true,
        media: true,
        notifications: true,
      })
    }
  }, []);

  function handleBackClick() {
    setShowPaymentLink(false);
    setShowPlugins(false);
    setShowApiIntegration(false);
    setShowStartHereCards(true);
  }

  return (
    <>
      {showStartHereCards &&
          <Box pt={{base: '180px', md: '80px', xl: '80px'}}>
              <Banner/>
            {/*<Grid*/}
            {/*    mb='20px'*/}
            {/*    gridTemplateColumns={{xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr'}}*/}
            {/*    gap={{base: '20px', xl: '20px'}}*/}
            {/*    display={{base: 'block', xl: 'grid'}}>*/}
              <Flex flexDirection='column' gridArea={{xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2'}}>
                  <Flex direction='column'>
                      <Flex
                          mt='45px'
                          mb='20px'
                          justifyContent='space-between'
                          direction={{base: 'column', md: 'row'}}
                          align={{base: 'start', md: 'center'}}>
                      </Flex>
                      <SimpleGrid columns={{base: 1, md: 3}} maxWidth={["100%", null, "1500px"]}
                                  gap={["20px", null, "25px"]}>
                        {/* Payment Link */}
                          <Card maxW={["100%", null, "500px"]}>
                              <CardBody>
                                  <Image
                                      src={PayLink}
                                      alt='Green double couch with wooden legs'
                                      borderRadius='lg'
                                  />
                                  <Stack mt='6' spacing='3'>
                                      <Heading size='xl'>Payment link</Heading>
                                      <Text>
                                          The Payment Link feature from Sprintcheckout allows for quick payment
                                          acceptance through a sharable link. This convenient solution requires minimal
                                          setup, making it suitable for businesses seeking simplicity and efficiency in
                                          transactions.
                                      </Text>
                                      <Button mt={3} onClick={() => handleShow('link')} alignSelf={"center"}
                                              justifySelf={"center"}
                                              variant="brand" minW={["100%", null, "100%"]}
                                              maxW={["100%", null, "50%"]}>
                                          Get started
                                      </Button>
                                  </Stack>
                              </CardBody>
                          </Card>
                        {/* Plugins */}
                          <Card maxW={["100%", null, "500px"]}>
                              <CardBody>
                                  <Image
                                      src={WooPlugin}
                                      alt='Green double couch with wooden legs'
                                      borderRadius='lg'
                                  />
                                  <Stack mt='6' spacing='3'>
                                      <Heading size='xl'>WooCommerce plugin</Heading>
                                      <Text>
                                          Sprintcheckout's WooCommerce plugin simplifies cryptocurrency transactions for
                                          online stores. It's easy to install and integrate, offering a hassle-free way
                                          to expand payment options for customers.
                                      </Text>
                                      <Button mt={3} onClick={() => handleShow('plugin')} alignSelf={"center"}
                                              justifySelf={"center"}
                                              variant="brand" minW={["100%", null, "100%"]}
                                              maxW={["100%", null, "50%"]}>
                                          Get started
                                      </Button>
                                  </Stack>
                              </CardBody>
                          </Card>
                          <Card maxW={["100%", null, "500px"]}>
                              <CardBody>
                                  <Image
                                      src={ApiInt}
                                      alt='Green double couch with wooden legs'
                                      borderRadius='lg'
                                  />
                                  <Stack mt='6' spacing='3'>
                                      <Heading size='xl'>API integration</Heading>
                                      <Text>
                                          For custom payment processes, Sprintcheckout’s API provides a versatile
                                          solution. It facilitates secure, tailor-made payment experiences, ideal for
                                          businesses with specific transaction requirements.
                                      </Text>
                                      <Link target={'_blank'} href={"https://calendly.com/sprintcheckout/30min"}>
                                          <Button mt={3} alignSelf={"center"}
                                                  justifySelf={"center"}
                                                  variant="brand" minW={["100%", null, "100%"]}
                                                  maxW={["100%", null, "50%"]}>
                                              Book intro call
                                          </Button>
                                      </Link>
                                  </Stack>
                              </CardBody>
                          </Card>
                      </SimpleGrid>
                  </Flex>
              </Flex>
            {/*</Grid>*/}
          </Box>
      }
      {showPaymentLink && (<CreatePaymentLink onBackClick={handleBackClick}/>)}
      {showPlugins && <Plugins onBackClick={handleBackClick} activeBulletsProp={activeBullets} fromWoocommerce={fromWoocommerce}/>}
      {showApiIntegration && <ApiIntegration onBackClick={handleBackClick}/>}
    </>
  );

}
