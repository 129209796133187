import React from 'react';
// Chakra imports
import {Box, Button, ButtonGroup, Flex, Grid, Heading, Text, useColorModeValue} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import LinkSteps from './LinkSteps';
import ApiIntegrationSteps from "./ApiIntegrationSteps";
// Assets

interface BackProps {
  onBackClick: () => void; // Assuming onBackClick is a function with no parameters and no return value
}


export default function ApiIntegration({onBackClick}: BackProps) {

  // Chakra Color Mode

  return (
    <>
      <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
        <Flex align='start' w='100%' justify='flex-start'>
          <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
            <Button width={130} variant="brand" onClick={onBackClick}>Back</Button>
          </ButtonGroup>
        </Flex>
      </Box>

      <Card mt={5} boxShadow='unset' transition='0.2s linear'>
        <Box>
        </Box>
        <Grid>
          <Heading size={"lg"}>API Integration</Heading>
          <ApiIntegrationSteps />
        </Grid>
      </Card>
    </>
  );
}
