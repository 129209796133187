/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Stack,
  Table, TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import Card from "../../../components/card/Card";
import React, {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {Controller, useForm} from "react-hook-form";
import {Select} from "chakra-react-select";
import {BiClipboard} from "react-icons/bi";
import {groupedOptions, PaymentSession} from "../interfaces";
import {generateAPIKey, getAPIKeys} from "../../../services/useAPICaller";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";


export default function Settings() {

  const [apiKeys, setApiKeys] = useState(Array<string>);
  // const [paymentLinks, setPaymentLinks] = useState(Array<PaymentSession>);
  const [createdPaymentLink, setCreatedPaymentLink] = useState("");
  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);
  const {isOpen: isAddPaymentLinkOpen, onOpen: onAddPaymentLinkOpen, onClose: onAddPaymentLinkClose} = useDisclosure()

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentData, setCurrentData] = useState(Array<PaymentSession>);
  const [isPaymentLinksLoading, setPaymentLinksLoading] = useState<boolean>(false);

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const {
    isOpen: isOpenSuccessSavePaymentLink,
    onOpen: onOpenSuccessSavePaymentLink,
    onClose: onCloseSuccessSavePaymentLink
  } = useDisclosure()
  const {
    handleSubmit: handleSubmitPaymentLink,
    register: registerPaymentLink,
    formState: {errors: errorsPaymentLink},
    watch,
    setValue,
    getValues: getValuesPaymentLink,
    reset: resetPaymentLink,
    control
  } = useForm();

  const isMinAmountEnabled = watch("isMinAmountEnabled", "false");

  useEffect(() => {
    getAPIKeys().then(keys => {
      setApiKeys(keys.data);
    });

  }, [resetPaymentLink]);

  useEffect(() => {
    getPaymentLinks(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    // If "No" is selected, clear the "Minimum Amount" input
    if (isMinAmountEnabled === "false") {
      setValue("pl_min_amount", ""); // This will clear the input field
    }
  }, [isMinAmountEnabled, setValue]);

  async function getPaymentLinks(page = 0, pageSize = 10) {

    try {
      setPaymentLinksLoading(true);
      let response = await axios.get(`${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/payment_session/payment_links?page=${page}&size=${pageSize}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setCurrentData(response.data.content);
      const totalItems = response.data.totalSize;
      setTotalPages(Math.ceil(totalItems / pageSize));
      setPaymentLinksLoading(false);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        console.log("Unauthorized")
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function generatePaymentLink(paymentSession: PaymentSession) {

    try {
      let paymentSettingsResponse = axios.post(process.env.REACT_APP_SPRINTCHECKOUT_HOST + '/api/checkout/v2/payment_session/payment_links', JSON.parse(JSON.stringify(paymentSession)), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      paymentSettingsResponse.then(result => {
        setIsFormSaving(false);
        if (result.status === 201) {
          getPaymentLinks();
          setCreatedPaymentLink(result.data);
          onAddPaymentLinkClose();
          resetPaymentLink();
          onOpenSuccessSavePaymentLink();
          setTimeout(() => {
            onCloseSuccessSavePaymentLink();
          }, 6 * 1000);
        }
      });
    } catch (error) {
      setIsFormSaving(false);
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function onSubmitPaymentLink(values: any) {
    setIsFormSaving(true);
    var concept = values['pl_concept'];
    var recipient = values['pl_recipient'];
    var orderId = values['pl_reference'];
    var amount = values['pl_amount'];
    var minAmount = values['pl_min_amount'];
    var editable = !!(amount && minAmount);
    var currency = values['pl_currency'].value;
    var template = values['pl_paymentTemplate'].value;

    const paymentSession: PaymentSession = {
      merchantId: null,
      concept: concept,
      recipient: recipient,
      orderId: orderId,
      sessionType: 'PAYMENT_LINK',
      amount: amount,
      minAmount: minAmount,
      editable: editable,
      currency: currency,
      template: template,
      paymentLink: null,
      createdDate: null
    }
    return new Promise(() => {
      generatePaymentLink(paymentSession);
    })
  }

  async function deleteApiKey(apiKey: string) {

    try {
      var keys = await axios.delete(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/keys/' + apiKey, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setApiKeys(keys.data);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        console.log("Unauthorized")
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function handleCopyApiKey(apiKey: string) {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey)
    }
  }

  function handleCopyPaymentLink() {
    if (createdPaymentLink) {
      navigator.clipboard.writeText(createdPaymentLink)
    }
  }

  // Chakra Color Mode
  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <Card mb='20px'>
        <SimpleGrid mb='20px' columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
          <Flex align='center' w='100%' justify='space-between' mb='30px'>
            <Text fontWeight='bold' fontSize='2xl' mb='4px'>
              API Keys
            </Text>
          </Flex>
          <Flex align='end' w='100%' justify='flex-end' mb='30px'>
            <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
              <Button variant="brand" onClick={() => generateAPIKey(setApiKeys)}>New API key</Button>
            </ButtonGroup>
          </Flex>
        </SimpleGrid>

        {(!apiKeys || apiKeys.length == 0) &&
            <Flex align='center' w='100%' justify='center' mb='30px'>
                <Text fontSize='l' mb='4px' color={"grey"}>
                    No API Keys available. Click on 'New API Key' button for generating a new one
                </Text>
            </Flex>
        }

        {apiKeys && apiKeys.map((apiKey, index) => {
          return <TableContainer borderColor={"black"}>
            <Table variant='simple'>
              <Tbody>
                <Tr>
                  <Td textAlign={"left"}>{apiKey}<Button ml={10} onClick={() => handleCopyApiKey(apiKey)}>Copy API key</Button></Td>
                  <Td textAlign={"right"}>
                    <Button onClick={() => deleteApiKey(apiKey)}>Delete</Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        })}
      </Card>
      <Flex align='center' w='100%' justify='center' mb='30px'>
        <Text fontSize='l' mb='4px' color={"grey"}>
          Learn how to use your API keys to process payments by checking out our <Link textDecoration={"underline"}
                                                                                       href="https://docs.sprintcheckout.com"
                                                                                       isExternal>Docs</Link>
        </Text>
      </Flex>

      <Card mb='20px'>
        <SimpleGrid mb='20px' columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
          <Flex align='center' w='100%' justify='space-between' mb='30px'>
            <Text fontWeight='bold' fontSize='2xl' mb='4px'>
              Payment links
            </Text>
          </Flex>
          <Flex align='end' w='100%' justify='flex-end' mb='30px'>
            <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
              <Button variant="brand" onClick={() => onAddPaymentLinkOpen()}>New payment link</Button>
            </ButtonGroup>
          </Flex>
        </SimpleGrid>

        {((!currentData || currentData.length == 0) && !isPaymentLinksLoading) &&
            <Flex align='center' w='100%' justify='center' mb='30px'>
                <Text fontSize='l' mb='4px' color={"grey"}>
                    No Payment links have been created. Click on 'New payment link' button for creating a new one
                </Text>
            </Flex>
        }

        {(isPaymentLinksLoading && (!currentData || currentData.length === 0)) &&
            <Flex align='center' w='100%' justify='center'>
                <Spinner thickness='6px' speed='0.65s' emptyColor='gray.200' color='blue.500' boxSize={24} mb={20}/>
            </Flex>
        }

        {currentData && currentData.length > 0 &&
            <TableContainer borderColor={"black"}>
                <Table variant='simple'>
                    <TableCaption>
                        <Button mr={2} onClick={prevPage} hidden={currentPage === 0} disabled={currentPage === 0}>
                            <ChevronLeftIcon />
                        </Button>
                        Page {currentPage + 1} of {totalPages}
                        <Button ml={2} onClick={nextPage} hidden={currentPage === totalPages - 1} disabled={currentPage === totalPages - 1}>
                            <ChevronRightIcon />
                        </Button>
                    </TableCaption>
                    <Thead>
                        <Tr>
                            <Th textAlign={"left"}> Session Id</Th>
                            <Th textAlign={"left"}> Concept</Th>
                            <Th textAlign={"left"}> Reference</Th>
                            <Th textAlign={"left"}> Created</Th>
                            <Th textAlign={"left"}> Amount</Th>
                            <Th textAlign={"left"}> Editable</Th>
                            <Th textAlign={"left"}> Min. Amount</Th>
                            <Th textAlign={"left"}> Template</Th>
                            <Th textAlign={"left"}> Link</Th>
                        </Tr>
                    </Thead>
                    <Tbody>

                      {currentData.map((paymentLink, index) => (
                        <Tr key={"pl_" + index}>
                          <Td textAlign={"left"}>{paymentLink.sessionId}</Td>
                          <Td textAlign={"left"}>{paymentLink.concept && paymentLink.concept.length > 0 && paymentLink.concept.length > 25
                            ? `${paymentLink.concept.slice(0, 25)}...`
                            : paymentLink.concept
                          }</Td>
                          <Td textAlign={"left"}>{paymentLink.orderId}</Td>
                          <Td textAlign={"left"}> {new Date(
                            parseInt(paymentLink.createdDate[0]),
                            parseInt(paymentLink.createdDate[1]) - 1,
                            parseInt(paymentLink.createdDate[2]),
                            parseInt(paymentLink.createdDate[3]),
                            parseInt(paymentLink.createdDate[4]),
                            parseInt(paymentLink.createdDate[5]),
                            +paymentLink.createdDate[6] / 1000000
                          ).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                          </Td>
                          <Td textAlign={"left"}>{paymentLink.amount}</Td>
                          <Td textAlign={"left"}>{paymentLink.editable? 'Yes' : 'No'}</Td>
                          <Td textAlign={"left"}>{paymentLink.minAmount}</Td>
                          <Td
                            textAlign={"left"}>{paymentLink.template.charAt(0).toUpperCase() + paymentLink.template.slice(1).toLowerCase()}</Td>
                          <Td textAlign={"left"}>
                            {paymentLink.sessionId ? (
                              <a href={`https://pay.sprintcheckout.com/?uid=${paymentLink.sessionId}`} target="_blank" rel="noopener noreferrer" style={{color: '#422AFB'}}>
                                Payment link
                              </a>
                            ) : (
                              "No payment link available"
                            )}
                          </Td>
                        </Tr>
                      ))
                      }
                    </Tbody>
                </Table>
            </TableContainer>
        }

      </Card>

      <Modal isOpen={isOpenSuccessSavePaymentLink} onClose={onCloseSuccessSavePaymentLink}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Payment link saved</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Alert status='success' variant='subtle' textAlign="center">
              <Flex align="center" justify="center" w={'100%'}>
                <AlertIcon/>
                <Text>Your payment link has been created.</Text>
              </Flex>
            </Alert>
            <Flex align="center" justify="center" w={'100%'} mt={3}>
              <Button variant={'brand'} onClick={handleCopyPaymentLink}><BiClipboard
                style={{marginLeft: '2px', marginRight: '3px'}}/> Copy Payment link</Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <form key={"payment-link-form"} id={"payment-link-form"} onSubmit={handleSubmitPaymentLink(onSubmitPaymentLink)}>
        <Modal
          isOpen={isAddPaymentLinkOpen}
          onClose={() => {
            onAddPaymentLinkClose();
          }}
        >
          <ModalOverlay/>
          <ModalContent>
            <ModalHeader>Add payment link</ModalHeader>
            <ModalCloseButton/>
            <ModalBody pb={6}>
              <Text mt={4} mb={2}>Template</Text>
              <Controller
                name="pl_paymentTemplate"
                control={control}
                rules={{required: 'This field is required.'}}
                render={({
                           field: {onChange, onBlur, value, name, ref}
                         }) => (
                  <FormControl id="pl_paymentTemplate">
                    <Select
                      key={'pl_paymentTemplate'}
                      name={name}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      options={[
                        {value: "INVOICE", label: 'Invoice'},
                        {value: "LIGHT", label: 'Light'},
                        // {value: "DONATION", label: 'Donation'},
                        // {value: "TIP", label: 'Tip'},
                        // {value: "PRODUCT", label: 'Product'}
                      ]}
                    />
                  </FormControl>
                )}
              />
              {errorsPaymentLink.pl_paymentTemplate &&
                  <Text ml={0.5} mb={2}
                        color={"red.400"}>{String(errorsPaymentLink.pl_paymentTemplate?.message)}</Text>}
              <Text mt={4} mb={2}>Concept</Text>
              <Textarea {...registerPaymentLink(`pl_concept`)}
                        placeholder="Your payment concept"
                        key="pl_concept"/>
              <Text mt={4} mb={2}>Recipient</Text>
              <Input {...registerPaymentLink(`pl_recipient`, {required: 'This field is required.'})}
                     placeholder="John Doe"
                     key="pl_recipient"/>
              {errorsPaymentLink.pl_recipient &&
                  <Text ml={0.5} mb={2}
                        color={"red.400"}>{String(errorsPaymentLink.pl_recipient?.message)}</Text>}
              {/* Reference Input */}
              <Text mt={4} mb={2}>Reference</Text>
              <Input {...registerPaymentLink(`pl_reference`)}
                     placeholder=""
                     key="pl_reference"/>
              {/* Amount Input */}
              <Text mt={4} mb={2}>Amount</Text>
              <Input {...registerPaymentLink('pl_amount', {
                required: 'This field is required.',
                pattern: {
                  value: /^\d*\.?\d*$/,
                  message: 'Please enter a valid number.',
                },
              })}/>
              {errorsPaymentLink.pl_amount &&
                  <Text ml={0.5} mb={2} color={"red.400"}>{String(errorsPaymentLink.pl_amount?.message)}</Text>}
              {/* Editable Amount Input */}
              <Text mt={4} mb={2}>Editable amount</Text>
              <Controller
                name="isMinAmountEnabled"
                control={control}
                defaultValue="false" // Make sure the default value is "false"
                render={({field}) => (
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <Radio value="true">Yes</Radio>
                      <Radio value="false">No</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />

              {/* Minimum Amount Input */}
              <FormControl mt={4}>
                <FormLabel htmlFor="pl_min_amount" color={isMinAmountEnabled === "false" ? "gray.400" : "black"}>
                  Minimum Amount
                </FormLabel>
                <Input
                  id="pl_min_amount"
                  {...registerPaymentLink("pl_min_amount", {
                    required: isMinAmountEnabled === "true" && "This field is required.",
                    validate: (value) => {
                      if (isMinAmountEnabled === "true") {
                        const minAmount = parseFloat(value);
                        const amount = parseFloat(watch("pl_amount"));
                        if (!isNaN(minAmount) && !isNaN(amount) && amount < minAmount) {
                          return "Amount must be greater than or equal to minimum amount";
                        }
                        return true;
                      }
                      return true;
                    },
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Please enter a valid number.",
                    },
                  })}
                  disabled={isMinAmountEnabled !== "true"}
                  placeholder="Minimum amount"
                />
                {errorsPaymentLink.pl_min_amount && (
                  <Text ml={0.5} mb={2} color="red.400">
                    {typeof errorsPaymentLink.pl_min_amount.message === "string" ? errorsPaymentLink.pl_min_amount.message : ""}
                  </Text>
                )}
              </FormControl>
              <Text mt={4} mb={2}>Currency</Text>
              <Controller
                name="pl_currency"
                control={control}
                rules={{required: 'This field is required.'}}
                render={({
                           field: {onChange, onBlur, value, name, ref}
                         }) => (
                  <FormControl id="pl_currency">
                    <Select
                      name={name}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      isSearchable={true}
                      options={groupedOptions}
                      key={'pl_currency'}
                    />
                  </FormControl>
                )}
              />
              {errorsPaymentLink.pl_currency &&
                  <Text ml={0.5} mb={2}
                        color={"red.400"}>{String(errorsPaymentLink.pl_currency?.message)}</Text>}
            </ModalBody>

            <ModalFooter>
              <Button mr={2} key={"pl-save-button"} variant="brand" type={"submit"}
                      form={"payment-link-form"} isLoading={isFormSaving}>Create</Button>
              <Button key={"as-cancel-button"} onClick={() => {
                onAddPaymentLinkClose();
                resetPaymentLink();
              }}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>

    </Box>);
}
