/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import axios, {AxiosError} from "axios";
import {
  ApiKeySecret,
  Autosettlement,
  Chain,
  PaymentSettings,
  Token,
  TokenAutoSettle
} from "../../../types/payment.types";

const chainsDisplayNames: Record<string, string> = {
  "ethereum-mainnet": "Ethereum",
  "zksync-goerli": "zkSync Era (goerli)",
  "zksync-mainnet": "zkSync Era",
  "polygon-mumbai": "Polygon zkEVM (mumbai)",
  "polygon-mainnet": "Polygon",
  "polygonzkevm-mainnet": "Polygon zkEVM",
  "optimism-mainnet": "Optimism",
  "optimism-goerli": "Optimism (goerli)",
  "avalanche-fuji": "Avalanche (fuji)",
  "avalanche-mainnet": "Avalanche",
  "arbitrum-mainnet": "Arbitrum",
  "scroll-mainnet": "Scroll",
  "linea-mainnet": "Linea",
  "base-mainnet": "Base",
  "binance-mainnet": "Binance BNB"
}

export default function Setup() {

  const [paymentSettingsBackend, setPaymentSettingsBackend] = useState<PaymentSettings>();
  const [chainsReady, setChainsReady] = useState<boolean>(false);
  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {isOpen: isAddApiKeyOpen, onOpen: onAddApiKeyOpen, onClose: onAddApiKeyClose} = useDisclosure()
  const [openedModalName, setOpenedModalName] = useState<string | null>(null);
  const textColorSecondary = 'secondaryGray.600';

  const openModal = (exchangeName: string) => {
    resetAutossetleApi();
    setOpenedModalName(exchangeName);
    onAddApiKeyOpen();
  };

  type ExchangeData = {
    tokenAutoSettleList: {
      token: string;
      tokenAddress: string;
      autoSettleCurrency: string;
    }[];
    exchangeApiKey: string;
    exchangeApiSecret: string;
    exchangeWebhook: string;
  }

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    getValues
  } = useForm()

  const {
    handleSubmit: handleSubmitAutosettleApi,
    register: registerAutosettleApi,
    control: controlAutosettleApi,
    formState: {errors: errorsAutosettleApi},
    getValues: getValuesAutosettleApi,
    reset: resetAutossetleApi
  } = useForm();

  function onSubmitAutosettleApi(values: any) {
    saveApiKeyAndSecret(values);
  }

  function onSubmit(values: any) {
    //console.log("form values", values);
    setIsFormSaving(true);
    return new Promise((resolve) => {
      let paymentSettingToUpdate = {} as PaymentSettings
      let chainsToUpdate = new Array<Chain>()
      //Process chains
      Object.keys(values).forEach(function (key) {
        if (key.startsWith("chain_")) {
          // Process Chains
          let chain = {} as Chain
          let strTokens = key.split("_");
          chain.name = strTokens.at(1);
          chain.network = strTokens.at(2);
          chain.id = Number(strTokens.at(3));

          if (key.startsWith("chain_")) {
            chain.active = values[key];
          }
          if (key.startsWith("publicAddress_")) {
            chain.publicAddress = values[key];
          }

          //Process Tokens
          chain.tokens = new Array<Token>();
          Object.keys(values).forEach(function (key) {
            if (key.startsWith("token_") && key.indexOf(chain.name + "_" + chain.network) !== -1) {
              let token = {} as Token
              let strTokens = key.split("_");
              token.symbol = strTokens.at(3).toUpperCase();
              token.active = values[key];
              chain.tokens.push(token);
            }
          });
          chainsToUpdate.push(chain);
        }
      });

      chainsToUpdate.forEach((chain) => {
        chain.publicAddress = values["publicAddress_" + chain.name + "_" + chain.network];
      })

      // Process auto-settle (exchanges) based on the form's values
      let autosettlements: Autosettlement[] = [];

      Object.keys(values).forEach(key => {
        const splitKey = key.split('_');
        if (key.includes('autosettle_tokenAddress_')) {
          const exchangeName = splitKey[0];  // Using the prefix as the exchange name
          const exchangeTokenName = splitKey.slice(-1)[0]; // Getting the token name from the end

          // Look for an existing Autosettlement for the given exchange
          let autosettlement = autosettlements.find(a => a.exchangeName === exchangeName);

          // If none is found, create one and add it to the list
          if (!autosettlement) {
            autosettlement = {
              exchangeName: exchangeName, // add exchange name to the autosettlement
              tokenAutoSettleList: [],
              exchangeApiKey: values[`${exchangeName}_exchangeApiKey`],
              exchangeApiSecret: values[`${exchangeName}_exchangeApiSecret`],
            };
            autosettlements.push(autosettlement);
          }

          const tokenAutoSettle: TokenAutoSettle = {
            token: exchangeTokenName,
            tokenAddress: values[`${exchangeName}_autosettle_tokenAddress_${exchangeTokenName}`],
            autoSettleCurrency: values[`${exchangeName}_autosettle_currency_${exchangeTokenName}`]
          };
          autosettlement.tokenAutoSettleList.push(tokenAutoSettle);

          // Process autosettle inside Tokens
          const exchangeTokenSymbol = splitKey.slice(-1)[0].split(' ')[1];
          const exchangeChainName = splitKey.slice(-1)[0].split(' ')[0];

          // Find the matching Chain object
          const chain = chainsToUpdate.find(c => c.name.toLowerCase() === exchangeChainName.toLowerCase());

          if (chain) {
            // Find the matching Token object inside the Chain
            const token = chain.tokens.find(t => t.symbol === exchangeTokenSymbol);

            if (token && !token.exchangeName && !token.isAutosettlementEnabled) {
              // Update Token properties
              token.cexPublicAddress = values[key];
              token.autoSettleCurrency = values[`${exchangeName}_autosettle_currency_${exchangeChainName} ${exchangeTokenSymbol}`];
              token.isAutosettlementEnabled = token.autoSettleCurrency !== 'NO';

              // If needed, update exchange-related properties
              token.exchangeName = exchangeName;
            }
          }
        }
      });

      paymentSettingToUpdate.businessUrl = values.businessUrl;
      paymentSettingToUpdate.businessDescription = values.businessDescription;
      paymentSettingToUpdate.merchantName = values.merchantName;
      paymentSettingToUpdate.chains = chainsToUpdate;
      paymentSettingToUpdate.merchantLogoUrl = values.merchantLogoUrl;
      paymentSettingToUpdate.autosettlements = autosettlements;
      updateMerchantPaymentSettings(paymentSettingToUpdate);
    })
  }

  function saveApiKeyAndSecret(values: any) {

    const results: ApiKeySecret[] = [];
    const map: Record<string, ApiKeySecret> = {};

    Object.keys(values).forEach(key => {

      const splitKey = key.split('_');
      const exchangeName = splitKey[0];
      const fieldType = splitKey[2];
      if (!map[exchangeName]) {
        map[exchangeName] = {
          exchangeName,
          apiKey: "",
          apiSecret: ""
        };
      }
      if (values[key] !== undefined) {
        if (fieldType === 'key') {
          map[exchangeName].apiKey = values[key];
        } else if (fieldType === 'secret') {
          map[exchangeName].apiSecret = values[key];
        }
      }
    });

    Object.values(map).forEach(exchange => {
      if (exchange.apiKey || exchange.apiSecret) { // Only add if either field is set.
        results.push(exchange);
      }
    });

    try {
      let paymentSettingsResponse = axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/apiKeys', JSON.parse(JSON.stringify(results.at(0))), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      paymentSettingsResponse.then(result => {
        if (result.status === 200) {
          setPaymentSettingsBackend(result.data);
          onAddApiKeyClose();
          onOpen();
          setTimeout(() => {
            onClose();
          }, 3 * 1000);
        }
      });
    } catch (error) {
      setIsFormSaving(false);
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function updateMerchantPaymentSettings(paymentSettingToUpdate: PaymentSettings) {

    try {
      let paymentSettingsResponse = axios.put(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', JSON.parse(JSON.stringify(paymentSettingToUpdate)), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      paymentSettingsResponse.then(result => {
        setIsFormSaving(false);
        if (result.status === 200) {
          onOpen();
          setTimeout(() => {
            onClose();
          }, 3 * 1000);
        }
      });
    } catch (error) {
      setIsFormSaving(false);
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  async function getMerchantPaymentSettings() {

    try {
      return await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("#/auth/set-up");
      }
      if (err.response?.status === 404) {
        console.log("Merchant Not Found")
        await createNewPaymentSettings()
      }
    }
  }

  async function createNewPaymentSettings() {
    try {
      var defaultPaymentSettings = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', {}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      setPaymentSettingsBackend(defaultPaymentSettings.data);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function generateValidationRule(value: string, chainName: string, chainNetwork: string, chainId: string) {
    const fieldName = `chain_${chainName}_${chainNetwork}_${chainId}`;
    return (getValues()[fieldName] === false || (value.length > 0 && getValues()[fieldName] === true)) ? true : 'Public address is required when network is active';
  }

  function capitalizeFirstChar(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleRevoke = async (autosettlement: Autosettlement) => {
    try {
      const url = `${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/payment_settings/apiKeys/${autosettlement.exchangeName}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });

      if (response.status === 200) {
        setPaymentSettingsBackend(response.data);
        console.log('API key and secret revoked successfully.');
      }
    } catch (error) {
      console.error('Failed to revoke API key and secret:', error);
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }

    }
  }

  useEffect(() => {
    getMerchantPaymentSettings().then(paymentSettings => {
      if (paymentSettings) {
        let castPaymentSettings: PaymentSettings = JSON.parse(JSON.stringify(paymentSettings.data));
        setPaymentSettingsBackend(castPaymentSettings);
        setChainsReady(true);
      }
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay/>
          <ModalContent>
            <ModalHeader>Settings saved</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
              <Alert status='success' variant='subtle'>
                <AlertIcon/>
                Your settings have been saved successfully!
              </Alert>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/***********************************   BUTTONS (SAVE)   **********************************/}
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
          <Flex align='end' w='100%' justify='flex-end'>
            <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
              <Button width={130} variant="brand" type={"submit"} isLoading={isFormSaving}
                      loadingText={"saving"}>Save</Button>
            </ButtonGroup>
          </Flex>
        </Box>
        {/***********************************   YOUR BUSINESS (NAME, URL, DESCRIPTION)   **********************************/}
        {!paymentSettingsBackend &&
            <Flex align='center' w='100%' justify='center' mt={250}>
                <Spinner
                    thickness='6px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    boxSize={24}
                />
            </Flex>
        }
        {paymentSettingsBackend && <Box pt={{base: '130px', md: '80px', xl: '30px'}}>
            <Card>
                <SimpleGrid columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
                    <Flex align='center' w='100%' justify='space-between' mb='30px'>
                        <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                            Your business
                        </Text>
                    </Flex>
                </SimpleGrid>

              {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                <Text mb='10px' mr='10px' ml={0.5}>
                    Name
                </Text>
                <Input {...register("merchantName")}
                       placeholder={"Alice Flowers"}
                       defaultValue={paymentSettingsBackend.merchantName}
                       mb='17px'
                ></Input>
              {/*</Flex>*/}
              {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                <Text mb='10px' ml={0.5}>Site URL</Text>
                <Input {...register("businessUrl",
                  {
                    required: 'URL is required',
                    pattern: {
                      value: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/i,
                      message: 'Invalid URL format'
                    }
                  })}
                       placeholder={"https://www.alice-flowers.com"}
                       defaultValue={paymentSettingsBackend.businessUrl}
                       mb='17px'
                ></Input>
              {errors.businessUrl &&
                  <Text ml={0.5} mt='-10px' mb={2} color={"red.400"}>{String(errors.businessUrl?.message)}</Text>}
              {/*<Tooltip placement='auto-start' label="The image referenced in the URL should fit 300x40 aspect ratio" aria-label='A tooltip'>*/}
                <Text mb='10px' ml={0.5}>Logo URL</Text>
                <Text mb='10px' ml={0.5} fontSize={"xs"}>Recommended dimensions: 300x40 pixels</Text>
              {/*</Tooltip>*/}
                <Input {...register("merchantLogoUrl",
                  {
                    pattern: {
                      value: /^(https?):\/\/[^\s$.?#].[^\s]*$/i,
                      message: 'Invalid URL format'
                    }
                  })}
                       placeholder={"https://via.placeholder.com/300x40"}
                       defaultValue={paymentSettingsBackend.merchantLogoUrl}
                       mb='17px'
                ></Input>
              {errors.merchantLogoUrl &&
                  <Text ml={0.5} mt='-10px' mb={2} color={"red.400"}>{String(errors.merchantLogoUrl?.message)}</Text>}
              {/*</Flex>*/}
              {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                <Text mb='10px' mr='10px' ml={0.5}>
                    Description
                </Text>
                <Textarea {...register("businessDescription")}
                          placeholder={"..."}
                          defaultValue={paymentSettingsBackend.businessDescription}
                          mb='17px'
                ></Textarea>
              {/*</Flex>*/}
            </Card>
        </Box>}

        {/***********************************   PAYMENT SETTINGS   **********************************/}
        {paymentSettingsBackend && <Box pt={{base: '180px', md: '80px', xl: '30px'}}>
            <Card mb='20px'>
                <SimpleGrid mb='20px' columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
                    <Flex align='center' w='100%' justify='space-between' mb='10px'>
                        <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                            Payout settings
                        </Text>
                    </Flex>
                </SimpleGrid>


                <Tabs variant='soft-rounded' colorScheme='blue' mb={5}>
                    <Flex as={TabList}>
                        <Tab mr={10}>Wallets</Tab>
                      {paymentSettingsBackend.autosettlements.map((autosettlement, index) => (
                        <Tab mr={10}
                             key={autosettlement.exchangeName}>{autosettlement.exchangeName.charAt(0).toUpperCase() + autosettlement.exchangeName.slice(1)}</Tab>
                      ))}
                    </Flex>

                    <TabPanels>
                      {/***********************************  WALLETS TAB  **********************************/}
                        <TabPanel mt={10}>
                          {/***********************************  WALLETS TAB CHAIN & PUBLIC ADDRESS   **********************************/}
                            <Tabs variant='soft-rounded' colorScheme='green'>
                                <TabList>
                                    <Tab>Mainnet</Tab>
                                    <Tab>Testnet</Tab>
                                </TabList>
                                <TabPanels>
                                  {/***********************************   MAINNET   **********************************/}
                                    <TabPanel>
                                      {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                        [key: string]: any
                                      }, chain) => {
                                        if (!acc[chain.name] && chain.network === "mainnet") {
                                          acc[chain.name] = chain;
                                        }
                                        return acc;
                                      }, {})).map((chain) => (
                                        <Box p={5} mb={5}
                                             mx="auto"
                                             bg="white"
                                             rounded="lg"
                                             overflow="hidden"
                                             border={'0.1px solid'}
                                             borderColor={'gray.200'}
                                        >
                                          <Box pb={1}>
                                            <Flex>
                                              <Text fontWeight='bold' fontSize='xl' mb='15px'>
                                                {chainsDisplayNames[chain.name + "-" + chain.network]}
                                              </Text>
                                              <Switch ml={5} mt={1.5} colorScheme="brand"
                                                      {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                      key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                      defaultChecked={chain.active}
                                              />
                                            </Flex>
                                          </Box>
                                          {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                                          <Text mb='4px' mr='15px' ml={0.5}>
                                            Your public address
                                          </Text>
                                          {chain &&
                                              <Input                        {...register("publicAddress_" + chain.name + "_" + chain.network, {
                                                validate: {
                                                  isPublicAddressMandatory: value => generateValidationRule(value, chain.name, chain.network, chain.id),
                                                }
                                              })}
                                                                            placeholder="0x0000000000000000000000000000000000000000"
                                                                            borderRadius="10px"
                                                                            mt={1} mb={6} size="lg"
                                                                            key={"publicAddress_" + chain.name + "_" + chain.network}
                                                                            defaultValue={chain.publicAddress}/>
                                          }
                                          {errors[`publicAddress_${chain.name}_${chain.network}`] &&
                                              <Text ml={0.5} mt='-10px' mb={2}
                                                    color={"red.400"}>{String(errors[`publicAddress_${chain.name}_${chain.network}`]?.message)}</Text>
                                          }
                                          {/*</Flex>*/}
                                          <Accordion allowToggle
                                                     mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                            <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                              <h2>
                                                <AccordionButton>
                                                  <Box as="span" flex='1' textAlign='left'>
                                                    <Text mb='4px' mr='15px' style={{whiteSpace: 'nowrap'}}>
                                                      Available tokens
                                                    </Text>
                                                  </Box>
                                                  <AccordionIcon/>
                                                </AccordionButton>
                                              </h2>
                                              <AccordionPanel pb={4}>
                                                <Table variant="simple">
                                                  <Tbody>
                                                    {chain.tokens && chain.tokens.map((token: Token) => {
                                                      return <Tr>
                                                        <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                        <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>

                                                        <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                    key={chain.name + chain.network + token.symbol}
                                                                    colorScheme="brand"
                                                                    defaultChecked={token.active}/></Td>
                                                      </Tr>;
                                                    })}
                                                  </Tbody>
                                                </Table>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </Box>
                                      ))}
                                    </TabPanel>
                                  {/***********************************   TESTNET   **********************************/}
                                    <TabPanel>
                                      {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                        [key: string]: any
                                      }, chain) => {
                                        if (!acc[chain.name] && chain.network !== "mainnet") {
                                          acc[chain.name] = chain;
                                        }
                                        return acc;
                                      }, {})).map((chain) => (
                                        <Box p={5} mb={5}
                                             mx="auto"
                                             bg="white"
                                             rounded="lg"
                                             overflow="hidden"
                                             boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)" // Adjust the values as needed
                                        >
                                          <Box>
                                            <Flex>
                                              <Text fontWeight='bold' fontSize='xl' mb='15px'>
                                                {chainsDisplayNames[chain.name + "-" + chain.network]}
                                              </Text>
                                              <Switch ml={5} mt={1.5} colorScheme="brand"
                                                      {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                      key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                      defaultChecked={chain.active}
                                              />
                                            </Flex>
                                          </Box>
                                          {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                                          <Text mb='4px' mr='15px' ml={0.5}>
                                            Your public address
                                          </Text>
                                          {chain &&
                                              <Input                        {...register("publicAddress_" + chain.name + "_" + chain.network, {
                                                validate: {
                                                  isPublicAddressMandatory: value => generateValidationRule(value, chain.name, chain.network, chain.id),
                                                }
                                              })}
                                                                            placeholder="0x0000000000000000000000000000000000000000"
                                                                            borderRadius="10px"
                                                                            mt={1} mb={6} size="lg"
                                                                            key={"publicAddress_" + chain.name + "_" + chain.network}
                                                                            defaultValue={chain.publicAddress}/>
                                          }
                                          {errors[`publicAddress_${chain.name}_${chain.network}`] &&
                                              <Text ml={0.5} mt='-15px' mb={2}
                                                    color={"red.400"}>{String(errors[`publicAddress_${chain.name}_${chain.network}`]?.message)}</Text>
                                          }
                                          {/*</Flex>*/}
                                          <Accordion allowToggle
                                                     mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                            <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                              <h2>
                                                <AccordionButton>
                                                  <Box as="span" flex='1' textAlign='left'>
                                                    <Text mb='4px' mr='15px' style={{whiteSpace: 'nowrap'}}>
                                                      Available tokens
                                                    </Text>
                                                  </Box>
                                                  <AccordionIcon/>
                                                </AccordionButton>
                                              </h2>
                                              <AccordionPanel pb={4}>
                                                <Table variant="simple">
                                                  <Tbody>
                                                    {chain.tokens && chain.tokens.map((token: Token, index: number) => {
                                                      return <Tr>
                                                        <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                        <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>
                                                        <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                    key={chain.name + chain.network + token.symbol}
                                                                    colorScheme="brand"
                                                                    defaultChecked={token.active}/></Td>
                                                      </Tr>;
                                                    })}
                                                  </Tbody>
                                                </Table>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </Box>
                                      ))}
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </TabPanel>
                      {/***********************************   AUTO-SETTLEMENT   **********************************/}
                      {/*TODO ADAPT THIS FORM TO STORE THE INFO IN THE CHAIN TOKENS*/}
                      {paymentSettingsBackend && paymentSettingsBackend.autosettlements.map((autosettlement, index) => (
                        <TabPanel mt={10} key={autosettlement.exchangeName}>
                          {autosettlement.tokenAutoSettleList.map(tokenInfo => (
                            // <Flex align='center' w='100%' justify='space-between' mb='30px' mt={5}>
                            <Card>
                              <Text mb='4px' mr='10px' style={{whiteSpace: 'nowrap'}}>
                                {capitalizeFirstChar(tokenInfo.token.replace('-', ' '))}
                              </Text>
                              <Input {...register(`${autosettlement.exchangeName}_autosettle_tokenAddress_${tokenInfo.token}`)}
                                     placeholder="0x0000000000000000000000000000000000000000"
                                     borderRadius="10px"
                                     size="lg"
                                     key={`${autosettlement.exchangeName}_autosettle_tokenAddress_${tokenInfo.token}`}
                                     defaultValue={tokenInfo.tokenAddress}
                              />
                              <Flex align='center' w='100%' my={5}>
                                <Text mr={5}>Autosettle: </Text>
                                {/*<Flex align='center' w='100%' justify='space-between' mb='30px' mt={5}>*/}
                                <Controller
                                  name={`${autosettlement.exchangeName}_autosettle_currency_${tokenInfo.token}`}
                                  control={control}
                                  defaultValue={tokenInfo.autoSettleCurrency}
                                  render={({field}) => (
                                    <RadioGroup {...field} value={field.value}>
                                      <Stack direction='row'>
                                        <Radio value='NO'>No</Radio>
                                        {!tokenInfo.token.includes('USDC') ?
                                          <Radio value='USD'>USD</Radio> : ""
                                        }
                                        <Radio value='EUR'>EUR</Radio>
                                        <Radio value='GBP'>GBP</Radio>
                                        {!tokenInfo.token.includes('USDC') ?
                                          <Radio value='USDC'>USDC</Radio> : ""
                                        }
                                      </Stack>
                                    </RadioGroup>
                                  )}
                                />
                              </Flex>
                              {/*</Flex>*/}
                            </Card>
                          ))}
                          <Divider/>
                          <Flex align='center' w='100%' justify='left' mb='20px' mt={30}>
                            <Text fontSize='l' mb='4px' fontStyle={"italic"} style={{whiteSpace: 'nowrap'}} mr={20}>
                              API key
                            </Text>
                            {(autosettlement.exchangeApiKey && autosettlement.exchangeApiKey !== '-') ?
                              <Text fontSize='l' mb='4px' fontStyle={"italic"}>
                                {autosettlement.exchangeApiKey}
                              </Text> :
                              <Text fontSize='l' mb='4px' color={"gray"} fontStyle={"italic"}
                                    style={{whiteSpace: 'nowrap'}}>
                                Click on Add API Key
                              </Text>
                            }
                            <Flex align='end' w='100%' justify='flex-end' mb='30px'>
                              <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
                                <Button variant="brand" onClick={() => openModal(autosettlement.exchangeName)}>Add API
                                  key</Button>
                                <Button
                                  color={"red.400"}
                                  bg={"transparent"}
                                  disabled={!autosettlement.exchangeApiKey || autosettlement.exchangeApiKey === '-'}
                                  onClick={() => handleRevoke(autosettlement)}
                                >
                                  Revoke
                                </Button>
                              </ButtonGroup>
                            </Flex>
                          </Flex>
                        </TabPanel>
                      ))}
                    </TabPanels>
                </Tabs>
            </Card>
        </Box>}
      </form>
      {/***********************************   AUTO-SETTLEMENT API KEY AND SECRET MODAL  **********************************/}

      <form key={"autosettle-api-form"} id={"autosettle-api-form"}
            onSubmit={handleSubmitAutosettleApi(onSubmitAutosettleApi)}>
        {paymentSettingsBackend && paymentSettingsBackend.autosettlements.map((autosettlement, index) => (
          <Modal
            isOpen={isAddApiKeyOpen && openedModalName === autosettlement.exchangeName}
            onClose={() => {
              onAddApiKeyClose();
              setOpenedModalName(null);
            }}
          >
            <ModalOverlay/>
            <ModalContent>
              <ModalHeader>Add API key</ModalHeader>
              <ModalCloseButton/>
              <ModalBody pb={6}>
                <Text mb={2}>API key</Text>
                <Input {...registerAutosettleApi(`${autosettlement.exchangeName}_api_key`)}
                       placeholder={`${autosettlement.exchangeName}-generated-api-key`}
                       key="${autosettlement.exchangeName}-generated-api-key"/>
                <Text mt={4} mb={2}>API secret</Text>
                <Input {...registerAutosettleApi(`${autosettlement.exchangeName}_api_secret`)}
                       placeholder={`${autosettlement.exchangeName}-generated-api-secret`}
                       key="${autosettlement.exchangeName}-generated-api-secret"/>
              </ModalBody>

              <ModalFooter>
                <Button key={"as-save-button"} variant="brand" type={"submit"}
                        form={"autosettle-api-form"}>Save</Button>
                <Button key={"as-cancel-button"} onClick={() => {
                  onAddApiKeyClose();
                  setOpenedModalName(null);
                  resetAutossetleApi();
                }}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ))}
      </form>
    </>
  );
}
