import React, {useEffect, useState} from 'react';
import Card from "../../../../components/card/Card";
import {Flex, Text, Select, useColorModeValue} from "@chakra-ui/react";
import Transaction from "./Transaction";
import axios, {AxiosError} from "axios";
import {TokenMetadata, TokenPrices} from "../../interfaces";

interface PieChartProps {
  dataMap: Map<string, number>;
}

export default function TotalAmountPerToken(props: { [x: string]: any }) {
  const {...rest} = props;

  const [dataTokenAmounts, setDataTokenAmounts] = useState<Map<TokenMetadata, number>>();
  const [tokenPrices, setTokenPrices] = useState<TokenPrices>({});
  const [totalValueUSD, setTotalValueUSD] = useState<number>(0);

  function formatPrice(price: number): string {
    // For small numbers, keep precision to avoid scientific notation
    if (price < 0.0001) {
      return price.toFixed(8);
    }
    if (price > 1) {
      return price.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    // For larger numbers, convert to a string and remove trailing zeros
    return price.toString().replace(/(\.\d*?[1-9])0+$/, "$1");
  }

  async function getTotalTokens() {

    try {
      let response = await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/orders/total/tokens', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });

      let map = convertToTokenMetadataMap(response.data);
      setDataTokenAmounts(map);
      await fetchTokenPrices(map);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        console.log("Unauthorized")
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  async function fetchTokenPrices(map: Map<TokenMetadata, number>) {
    const ids = Array.from(map.keys()).map(tm => tm.apiId).join(',');
    try {
      let priceResponse = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd`);
      setTokenPrices(priceResponse.data);
    } catch (error) {
      console.error('Error fetching token prices', error);
    }
  }

  function convertToTokenMetadataMap(data: { [key: string]: number }): Map<TokenMetadata, number> {
    const map = new Map<TokenMetadata, number>();

    Object.entries(data).forEach(([key, amount]) => {
      const tokenMetadata = parseKeyToTokenMetadata(key);
      if (tokenMetadata) {
        map.set(tokenMetadata, amount);
      }
    });

    return map;
  }

  async function calculateTotalValueUSD() {
    let totalValue = 0;

    if (dataTokenAmounts && tokenPrices) {
      dataTokenAmounts.forEach((amount, tokenMetadata) => {
        const price = tokenPrices[tokenMetadata.apiId]?.usd;
        if (price) {
          totalValue += amount * price;
        }
      });

      setTotalValueUSD(totalValue);
    }
  }

  function parseKeyToTokenMetadata(key: string): TokenMetadata | null {
    const regex = /TokenMetadata\[ticker=(.*?), apiId=(.*?), iconUrl=(.*?)\]/;
    const match = key.match(regex);
    if (match) {
      const [, ticker, apiId, iconUrl] = match;
      return { ticker, apiId, iconUrl };
    }
    return null; // or handle this case as needed
  }

  useEffect(() => {
    getTotalTokens();
  }, []);

  useEffect(() => {
    calculateTotalValueUSD(); // This should be called whenever dataTokenAmounts or tokenPrices changes
  }, [dataTokenAmounts, tokenPrices]);

  // Chakra Color Mode
  const blueIcon = useColorModeValue('blue.500', 'white');
  const greenIcon = useColorModeValue('green.500', 'white');
  const yellowIcon = useColorModeValue('yellow.500', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textHover = useColorModeValue(
    {color: 'secondaryGray.900', bg: 'unset'},
    {color: 'secondaryGray.500', bg: 'unset'},
  );
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset',
  );
  return (
    <Card flexDirection="column" w="100%" {...rest}>
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        // bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex flexDirection="column" me="20px">
            <Text color="white" fontSize="sm" fontWeight="500">
              Total Volume
            </Text>
            <Text
              color="white"
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              ${totalValueUSD && formatPrice(totalValueUSD)}
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            ms="auto"
            justify="space-between"
            align="flex-end"
          >
          </Flex>
          {/*<Select*/}
          {/*  fontSize="sm"*/}
          {/*  variant="subtle"*/}
          {/*  defaultValue="monthly"*/}
          {/*  width="unset"*/}
          {/*  fontWeight="700"*/}
          {/*>*/}
          {/*  <option value="all">All</option>*/}
          {/*  <option value="daily">Daily</option>*/}
          {/*  <option value="monthly">Monthly</option>*/}
          {/*  <option value="yearly">Yearly</option>*/}
          {/*</Select>*/}
        </Flex>
      </Flex>

      <Flex direction="column" maxHeight="350px" overflowY="auto">
        {dataTokenAmounts && dataTokenAmounts.size > 0 && tokenPrices && Array.from(dataTokenAmounts.entries())
          .sort(([tokenMetadataA, amountA], [tokenMetadataB, amountB]) => {
            // Calculate the sums for comparison
            const sumA = (tokenPrices[tokenMetadataA.apiId]?.usd || 0) * amountA;
            const sumB = (tokenPrices[tokenMetadataB.apiId]?.usd || 0) * amountB;
            // Sort in descending order
            return sumB - sumA;
          })
          .map(([tokenMetadata, amount], index) => (
            <Transaction
              key={index}
              mb="20px"
              name={tokenMetadata.ticker}
              sum={`${formatPrice((tokenPrices[tokenMetadata.apiId]?.usd * amount))}`}
              tokenAmount={amount}
              currentPrice={tokenPrices[tokenMetadata.apiId]?.usd}
              icon={tokenMetadata.iconUrl}
            />
          ))}

      </Flex>
    </Card>
  );
}
