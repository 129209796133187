// Chakra imports
import {Flex, Image, useColorModeValue} from '@chakra-ui/react';
import spcDashLogo from 'assets/img/spc_merchant_dashboard.jpeg';
// Custom components
import {HSeparator} from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode

	return (
		<Flex alignItems='center' flexDirection='column'>
		   <Image src={spcDashLogo} />
			{/*<HorizonLogo h='26px' w='175px' my='32px' color={logoColor} />*/}
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
