/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect} from "react";
// Chakra imports
import {Box, Button, Flex, Heading, Icon, Link, Text, useColorModeValue,} from "@chakra-ui/react";
import {GoogleLogin} from '@react-oauth/google';

// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/bg_spc_web_croped.jpg";
import {FcGoogle} from "react-icons/fc";
import Cookies from 'js-cookie';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const [redirectAfterLogin, setRedirectAfterLogin] = React.useState(false);
  const handleClick = () => setShow(!show);

  useEffect(() => {
    if (redirectAfterLogin) {
      window.location.replace("/#/admin/start-here");
    }

  }, [redirectAfterLogin]);

  const responseMessage = (response: any) => {
    // Cookies.set('JWT', response.credential, { secure: true, sameSite: "none" });
    localStorage.setItem('jwtToken', response.credential);
    setRedirectAfterLogin(true);
  };
  const errorMessage = (error: any) => {
    console.log(error);
  };

  // @ts-ignore
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Sign in with your Google account and start to configure your merchant!
            <Box display="block">
              By signing in, you agree to our <Link href={'https://sprintcheckout.com/privacy-policy/'} textDecoration="none" _hover={{ textDecoration: "none" }}>Privacy Policy</Link>
            </Box>
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <GoogleLogin onSuccess={responseMessage} onError={() => errorMessage} shape={"circle"} />
          {/*<Flex align='center' mb='25px'>*/}
          {/*  <HSeparator />*/}
          {/*  <Text color='gray.400' mx='14px'>*/}
          {/*    or*/}
          {/*  </Text>*/}
          {/*  <HSeparator />*/}
          {/*</Flex>*/}
          {/*<FormControl>*/}
          {/*  <FormLabel*/}
          {/*    display='flex'*/}
          {/*    ms='4px'*/}
          {/*    fontSize='sm'*/}
          {/*    fontWeight='500'*/}
          {/*    color={textColor}*/}
          {/*    mb='8px'>*/}
          {/*    Email<Text color={brandStars}>*</Text>*/}
          {/*  </FormLabel>*/}
          {/*  <Input*/}
          {/*    isRequired={true}*/}
          {/*    variant='auth'*/}
          {/*    fontSize='sm'*/}
          {/*    ms={{ base: "0px", md: "0px" }}*/}
          {/*    type='email'*/}
          {/*    placeholder='mail@simmmple.com'*/}
          {/*    mb='24px'*/}
          {/*    fontWeight='500'*/}
          {/*    size='lg'*/}
          {/*  />*/}
          {/*  <FormLabel*/}
          {/*    ms='4px'*/}
          {/*    fontSize='sm'*/}
          {/*    fontWeight='500'*/}
          {/*    color={textColor}*/}
          {/*    display='flex'>*/}
          {/*    Password<Text color={brandStars}>*</Text>*/}
          {/*  </FormLabel>*/}
          {/*  <InputGroup size='md'>*/}
          {/*    <Input*/}
          {/*      isRequired={true}*/}
          {/*      fontSize='sm'*/}
          {/*      placeholder='Min. 8 characters'*/}
          {/*      mb='24px'*/}
          {/*      size='lg'*/}
          {/*      type={show ? "text" : "password"}*/}
          {/*      variant='auth'*/}
          {/*    />*/}
          {/*    <InputRightElement display='flex' alignItems='center' mt='4px'>*/}
          {/*      <Icon*/}
          {/*        color={textColorSecondary}*/}
          {/*        _hover={{ cursor: "pointer" }}*/}
          {/*        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}*/}
          {/*        onClick={handleClick}*/}
          {/*      />*/}
          {/*    </InputRightElement>*/}
          {/*  </InputGroup>*/}
          {/*  <Flex justifyContent='space-between' align='center' mb='24px'>*/}
          {/*    <FormControl display='flex' alignItems='center'>*/}
          {/*      <Checkbox*/}
          {/*        id='remember-login'*/}
          {/*        colorScheme='brandScheme'*/}
          {/*        me='10px'*/}
          {/*      />*/}
          {/*      <FormLabel*/}
          {/*        htmlFor='remember-login'*/}
          {/*        mb='0'*/}
          {/*        fontWeight='normal'*/}
          {/*        color={textColor}*/}
          {/*        fontSize='sm'>*/}
          {/*        Keep me logged in*/}
          {/*      </FormLabel>*/}
          {/*    </FormControl>*/}
          {/*    <NavLink to='/auth/forgot-password'>*/}
          {/*      <Text*/}
          {/*        color={textColorBrand}*/}
          {/*        fontSize='sm'*/}
          {/*        w='124px'*/}
          {/*        fontWeight='500'>*/}
          {/*        Forgot password?*/}
          {/*      </Text>*/}
          {/*    </NavLink>*/}
          {/*  </Flex>*/}
          {/*  <Button*/}
          {/*    fontSize='sm'*/}
          {/*    variant='brand'*/}
          {/*    fontWeight='500'*/}
          {/*    w='100%'*/}
          {/*    h='50'*/}
          {/*    mb='24px'>*/}
          {/*    Sign In*/}
          {/*  </Button>*/}
          {/*</FormControl>*/}
          {/*<Flex*/}
          {/*  flexDirection='column'*/}
          {/*  justifyContent='center'*/}
          {/*  alignItems='start'*/}
          {/*  maxW='100%'*/}
          {/*  mt='0px'>*/}
          {/*  <Text color={textColorDetails} fontWeight='400' fontSize='14px'>*/}
          {/*    Not registered yet?*/}
          {/*    <NavLink to='/auth/sign-up'>*/}
          {/*      <Text*/}
          {/*        color={textColorBrand}*/}
          {/*        as='span'*/}
          {/*        ms='5px'*/}
          {/*        fontWeight='500'>*/}
          {/*        Create an Account*/}
          {/*      </Text>*/}
          {/*    </NavLink>*/}
          {/*  </Text>*/}
          {/*</Flex>*/}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
