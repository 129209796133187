/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect, useState} from 'react';

// Chakra imports
import {Box, Flex, Grid, Text, useColorModeValue} from '@chakra-ui/react';
import Card from 'components/card/Card';
import PercentagesPerToken from "./components/PercentagesPerToken";
import axios, {AxiosError} from "axios";
import PercentagesPerChain from "./components/PercentagesPerChain";
import TotalAmountPerToken from "./components/TotalAmountPerToken";

interface TokenPercentage {
  // merchantId: string;
  token: string;
  percentage: number
}



export default function Reports() {

  const textColor = useColorModeValue('secondaryGray.900', 'white');


  return (
    <>
      <Flex
        direction={{ base: 'column', xl: 'row' }}
        pt={{ base: '130px', md: '80px', xl: '80px' }}
      >
        <Flex direction="column" width="stretch">

          <Grid
            gap="20px"
            gridTemplateColumns={{
              md: 'repeat(2, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gridTemplateRows={{
              md: 'repeat(2, 1fr)',
              '2xl': '1fr',
            }}
            mb="20px"
          >
            <Flex gridArea={{ md: '1 / 1 / 2 / 2', '2xl': '1 / 1 / 2 / 2' }}>
              {/*<DailyTraffic />*/}
              <TotalAmountPerToken />
            </Flex>
            <Flex gridArea={{ md: '1 / 2 / 2 / 3', '2xl': '1 / 2 / 2 / 3' }}>
              {/*<ProjectStatus />*/}
              <PercentagesPerToken />
            </Flex>
            <Flex gridArea={{ md: ' 2 / 1 / 3 / 3', '2xl': '1 / 3 / 2 / 4' }}>
              {/*<ProfitEstimation />*/}
              <PercentagesPerChain />
            </Flex>
          </Grid>

        </Flex>

      </Flex>

    </>
  )
    ;

}
