import React, {useEffect, useState} from 'react';
// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl, FormLabel,
  Icon,
  Image,
  Input, Radio, RadioGroup,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  useColorModeValue,
  useTheme
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import {MdKeyboardArrowLeft} from "react-icons/md";
// Assets
import InputField from 'components/fields/InputField';
import {Controller, useForm} from "react-hook-form";
import axios, {AxiosError} from "axios";

import {Select} from "chakra-react-select";
import spcNotifications from 'assets/img/notif.png';
import {groupedOptions, PaymentSession} from "../../interfaces";
import {Chain, PaymentSettings, Token} from 'types/payment.types';
import {chainsDisplayNames} from "../../../../variables/constants";
import {getMerchantPaymentSettings} from "../../../../services/useAPICaller";

interface CreatePaymentLinkProps {
  onBackClick: () => void;
}

const textColorSecondary = 'secondaryGray.900';

export default function CreatePaymentLink({onBackClick}: CreatePaymentLinkProps) {

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    getValues
  } = useForm()

  const {
    handleSubmit: handleSubmitPaymentLink,
    register: registerPaymentLink,
    control: controlPaymentLink,
    watch,
    setValue,
    formState: {errors: errorsPaymentLink},
    getValues: getValuesPaymentLink,
    reset: resetPaymentLink,
  } = useForm();
  const isMinAmountEnabled = watch("isMinAmountEnabled", "false");

  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [createdPaymentLink, setCreatedPaymentLink] = useState("");

  useEffect(() => {
    // If "No" is selected, clear the "Minimum Amount" input
    if (isMinAmountEnabled === "false") {
      setValue("pl_min_amount", ""); // This will clear the input field
    }
  }, [isMinAmountEnabled, setValue]);

  function generatePaymentLink(paymentSession: PaymentSession) {

    try {
      let paymentSettingsResponse = axios.post(process.env.REACT_APP_SPRINTCHECKOUT_HOST + '/api/checkout/v2/payment_session/payment_links', JSON.parse(JSON.stringify(paymentSession)), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      paymentSettingsResponse.then(result => {
        setIsFormSaving(false);
        if (result.status === 201) {
          setPaymentLink(result.data);
          setCreatedPaymentLink(result.data);
          resetPaymentLink();
          notificationsTab.current.click();
        }
      });
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function updateMerchantPaymentSettings(paymentSettingToUpdate: PaymentSettings) {

    try {
      let paymentSettingsResponse = axios.put(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', JSON.parse(JSON.stringify(paymentSettingToUpdate)), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      mediaTab.current.click();
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function onSubmit(values: any) {
    return new Promise((resolve) => {
      let paymentSettingToUpdate = {} as PaymentSettings
      let chainsToUpdate = new Array<Chain>()
      //Process chains
      Object.keys(values).forEach(function (key) {
        if (key.startsWith("chain_")) {
          // Process Chains
          let chain = {} as Chain
          let strTokens = key.split("_");
          chain.name = strTokens.at(1);
          chain.network = strTokens.at(2);
          chain.id = Number(strTokens.at(3));

          if (key.startsWith("chain_")) {
            chain.active = values[key];
          }
          if (key.startsWith("publicAddress_")) {
            chain.publicAddress = values[key];
          }

          //Process Tokens
          chain.tokens = new Array<Token>();
          Object.keys(values).forEach(function (key) {
            if (key.startsWith("token_") && key.indexOf(chain.name + "_" + chain.network) !== -1) {
              let token = {} as Token
              let strTokens = key.split("_");
              token.symbol = strTokens.at(3).toUpperCase();
              token.active = values[key];
              chain.tokens.push(token);
            }
          });
          chainsToUpdate.push(chain);
        }
      });

      chainsToUpdate.forEach((chain) => {
        chain.publicAddress = values["publicAddress_" + chain.name + "_" + chain.network];
      })

      paymentSettingToUpdate.businessUrl = values.businessUrl;
      paymentSettingToUpdate.businessDescription = values.businessDescription;
      paymentSettingToUpdate.merchantName = values.merchantName;
      paymentSettingToUpdate.chains = chainsToUpdate;
      paymentSettingToUpdate.merchantLogoUrl = values.merchantLogoUrl;
      updateMerchantPaymentSettings(paymentSettingToUpdate);
    })
  }

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    notifications: false,
  });

  const productTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const mediaTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const notificationsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const theme = useTheme();
  //eslint-disable-next-line
  const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
  //eslint-disable-next-line
  const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
  const brand = useColorModeValue(lineColor, lineColorDark);

  // ****************** BACKEND *************** //
  const [paymentSettingsBackend, setPaymentSettingsBackend] = useState<PaymentSettings>();

  function capitalizeFirstChar(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function generateValidationRule(value: string, chainName: string, chainNetwork: string, chainId: string) {
    const fieldName = `chain_${chainName}_${chainNetwork}_${chainId}`;
    return (getValues()[fieldName] === false || (value.length > 0 && getValues()[fieldName] === true)) ? true : 'Public address is required when network is active';
  }

  function onSubmitPaymentLink(values: any) {
    setIsFormSaving(true);
    var template = values['pl_paymentTemplate'].value;
    var concept = values['pl_concept'];
    var recipient = values['pl_recipient'];
    var orderId = values['pl_reference'];
    var amount = values['pl_amount'];
    var minAmount = values['pl_min_amount'];
    var editable = !!(amount && minAmount);
    var currency = values['pl_currency'] && values['pl_currency'].value ? values['pl_currency'].value : '';

    const paymentSession: PaymentSession = {
      merchantId: null,
      concept: concept,
      recipient: recipient,
      orderId: orderId,
      sessionType: 'PAYMENT_LINK',
      amount: amount,
      minAmount: minAmount,
      editable: editable,
      currency: currency,
      template: template,
      paymentLink: null,
      createdDate: null
    }
    return new Promise(() => {
      generatePaymentLink(paymentSession);
    })
  }

  useEffect(() => {
    getMerchantPaymentSettings(setPaymentSettingsBackend).then(paymentSettings => {
      if (paymentSettings) {
        let castPaymentSettings: PaymentSettings = JSON.parse(JSON.stringify(paymentSettings.data));
        setPaymentSettingsBackend(castPaymentSettings);
        // setChainsReady(true);
      }
    });
  }, []);

  function handleCopyLinkClick() {
    if (paymentLink) {
      navigator.clipboard.writeText(paymentLink)
    }
  }

  return (
    <>
      {/*<Box pt={{base: '130px', md: '80px', xl: '80px'}} mt={8}>*/}
      {/*  <Flex align='start' w='100%' justify='flex-start'>*/}
      {/*    <Flex align='center' ml={3} onClick={onBackClick} cursor={'pointer'}>*/}
      {/*      <Icon as={MdKeyboardArrowLeft} h='18px' w='18px'/>*/}
      {/*      <Text fontSize={18} fontWeight={'bold'}>Back</Text>*/}
      {/*    </Flex>*/}
      {/*  </Flex>*/}
      {/*</Box>*/}

      {!paymentSettingsBackend &&
          <Flex align='center' w='100%' justify='center' mt={'20%'}>
              <Spinner thickness='6px' speed='0.65s' emptyColor='gray.200' color='blue.500' boxSize={24} mb={20}/>
          </Flex>
      }

      {paymentSettingsBackend &&
          <Flex
              direction="column"
              minH="100vh"
              align="center"
              pt={{sm: '125px', lg: '75px'}}
              position="relative"
          >
              <Box
                  h="45vh"
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  position="absolute"
                  w="100%"
                  borderRadius="20px">
                  <Flex align='start' w='100%' justify='flex-start' mt={5} ml={5}>
                      <Flex align='center' ml={3} onClick={onBackClick} cursor={'pointer'}>
                          <Icon as={MdKeyboardArrowLeft} h='18px' w='18px' color={'white'}/>
                          <Text color={'white'} fontSize={18} fontWeight={'bold'}>Back</Text>
                      </Flex>
                  </Flex>
              </Box>

              <Tabs
                  variant="unstyled"
                  mt={{base: '60px', md: '165px'}}
                  zIndex="0"
                  display="flex"
                  flexDirection="column"
              >
                  <TabList
                      display="flex"
                      alignItems="center"
                      alignSelf="center"
                      justifySelf="center"
                  >
                      <Tab
                          _focus={{border: '0px', boxShadow: 'unset'}}
                          ref={productTab}
                          w={{sm: '120px', md: '250px', lg: '300px'}}
                          onClick={() =>
                            setActiveBullets({
                              product: true,
                              media: false,
                              notifications: false,
                            })
                          }
                      >
                          <Flex
                              direction="column"
                              justify="center"
                              align="center"
                              position="relative"
                              _before={{
                                content: "''",
                                width: {sm: '120px', md: '250px', lg: '300px'},
                                height: '3px',
                                bg: activeBullets.media ? 'white' : 'brand.400',
                                left: {sm: '12px', md: '40px'},
                                top: {
                                  sm: activeBullets.product ? '6px' : '4px',
                                  md: null,
                                },
                                position: 'absolute',
                                bottom: activeBullets.product ? '40px' : '38px',

                                transition: 'all .3s ease',
                              }}
                          >
                              <Box
                                  zIndex="1"
                                  border="2px solid"
                                  borderColor={activeBullets.product ? 'white' : 'brand.400'}
                                  bgGradient="linear(to-b, brand.400, brand.600)"
                                  w="16px"
                                  h="16px"
                                  mb="8px"
                                  borderRadius="50%"
                              />
                              <Text
                                  color={activeBullets.product ? 'white' : 'gray.300'}
                                  fontWeight={activeBullets.product ? 'bold' : 'normal'}
                                  display={{sm: 'none', md: 'block'}}
                              >
                                  Set up
                              </Text>
                          </Flex>
                      </Tab>
                      <Tab
                          _focus={{border: '0px', boxShadow: 'unset'}}
                          ref={mediaTab}
                          w={{sm: '120px', md: '250px', lg: '300px'}}
                          onClick={() =>
                            setActiveBullets({
                              product: true,
                              media: true,
                              notifications: false,
                            })
                          }
                      >
                          <Flex
                              direction="column"
                              justify="center"
                              align="center"
                              position="relative"
                              _before={{
                                content: "''",
                                width: {sm: '120px', md: '250px', lg: '300px'},
                                height: '3px',
                                bg: activeBullets.notifications ? 'white' : 'brand.400',
                                left: {sm: '12px', md: '28px'},
                                top: '6px',
                                position: 'absolute',
                                bottom: activeBullets.media ? '40px' : '38px',

                                transition: 'all .3s ease',
                              }}
                          >
                              <Box
                                  zIndex="1"
                                  border="2px solid"
                                  borderColor={activeBullets.media ? 'white' : 'brand.400'}
                                  bgGradient="linear(to-b, brand.400, brand.600)"
                                  w="16px"
                                  h="16px"
                                  mb="8px"
                                  borderRadius="50%"
                              />
                              <Text
                                  color={activeBullets.media ? 'white' : 'gray.300'}
                                  fontWeight={activeBullets.media ? 'bold' : 'normal'}
                                  display={{sm: 'none', md: 'block'}}
                              >
                                  Get paid
                              </Text>
                          </Flex>
                      </Tab>
                      <Tab
                          _focus={{border: '0px', boxShadow: 'unset'}}
                          ref={notificationsTab}
                          w={{sm: '120px', md: '250px', lg: '300px'}}
                          onClick={() =>
                            setActiveBullets({
                              product: true,
                              media: true,
                              notifications: true,
                            })
                          }
                      >
                          <Flex
                              direction="column"
                              justify="center"
                              align="center"
                              position="relative"
                          >
                              <Box
                                  zIndex="1"
                                  border="2px solid"
                                  borderColor={activeBullets.notifications ? 'white' : 'brand.400'}
                                  bgGradient="linear(to-b, brand.400, brand.600)"
                                  w="16px"
                                  h="16px"
                                  mb="8px"
                                  borderRadius="50%"
                              />
                              <Text
                                  color={activeBullets.notifications ? 'white' : 'gray.300'}
                                  fontWeight={activeBullets.notifications ? 'bold' : 'normal'}
                                  display={{sm: 'none', md: 'block'}}
                              >
                                  Notifications
                              </Text>
                          </Flex>
                      </Tab>
                  </TabList>
                  <TabPanels mt="24px" maxW={{md: '90%', lg: '100%'}} mx="auto">
                    {/***********************************   SET UP WIZARD   **********************************/}
                      <form onSubmit={handleSubmit(onSubmit)}>

                          <TabPanel
                              w={{sm: '330px', md: '700px', lg: '850px'}}
                              p="0px"
                              mx="auto"
                          >
                              <Card p="30px">
                                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                      Your Business
                                  </Text>
                                  <Flex direction="column" w="100%">
                                      <SimpleGrid columns={{base: 1, md: 2}} gap="20px">
                                          <Stack direction="column" gap="20px">
                                              <InputField
                                                  register={register}
                                                  mb="0px"
                                                  id="merchantName"
                                                  placeholder="eg. Alice Flowers"
                                                  label="Business name"
                                                  defaultValue={paymentSettingsBackend.merchantName}
                                              />
                                              <InputField
                                                  register={register}
                                                  mb="0px"
                                                  id="businessUrl"
                                                  placeholder="eg. https://alice-flowers.com"
                                                  defaultValue={paymentSettingsBackend.businessUrl}
                                                  label="Site URL"
                                              />
                                          </Stack>
                                          <Stack direction="column" gap="20px">
                                              <InputField
                                                  register={register}
                                                  mb="0px"
                                                  id="merchantLogoUrl"
                                                  placeholder="eg. https://via.placeholder.com/your-logo.png"
                                                  label="Logo URL (optional)"
                                                  defaultValue={paymentSettingsBackend.merchantLogoUrl}
                                              />

                                            {/*<TextField*/}
                                            {/*  h="146px"*/}
                                            {/*  mb="0px"*/}
                                            {/*  id="Description"*/}
                                            {/*  placeholder="Short description about the business"*/}
                                            {/*  label="Description"*/}
                                            {/*/>*/}
                                          </Stack>

                                      </SimpleGrid>

                                      <Tabs variant='soft-rounded' colorScheme='blue' mb={5}>
                                          <TabPanels>
                                            {/***********************************  WALLETS TAB  **********************************/}
                                              <TabPanel mt={10} ml={-3}>
                                                {/***********************************  WALLETS TAB CHAIN & PUBLIC ADDRESS   **********************************/}
                                                  <Tabs variant='soft-rounded' colorScheme='green'>
                                                      <TabList>
                                                          <Tab>Mainnet</Tab>
                                                          <Tab>Testnet</Tab>
                                                      </TabList>
                                                      <TabPanels>
                                                        {/***********************************   MAINNET   **********************************/}
                                                          <TabPanel>
                                                            {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                                              [key: string]: any
                                                            }, chain) => {
                                                              if (!acc[chain.name] && chain.network === "mainnet") {
                                                                acc[chain.name] = chain;
                                                              }
                                                              return acc;
                                                            }, {})).map((chain) => (
                                                              <Box mb={2}>

                                                                <Accordion allowToggle outline={"0px solid #e0dddd"}
                                                                           borderStyle={"none"}>
                                                                  <AccordionItem borderStyle={"none"}>
                                                                    <h2>
                                                                      <AccordionButton>
                                                                        <Flex justifySelf={'flex-start'}
                                                                              minW={['1', null, '150px']}>
                                                                          <Switch mr={5} mt={1} colorScheme="brand"
                                                                                  {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                                                  key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                                                  defaultChecked={chain.active}
                                                                          />
                                                                          <Text fontWeight='bold' fontSize='lg'
                                                                                mb='5px'>
                                                                            {chainsDisplayNames[chain.name + "-" + chain.network]}
                                                                          </Text>
                                                                        </Flex>
                                                                        <Box as="span" flex='1' textAlign='left'>
                                                                        </Box>
                                                                        <AccordionIcon/>
                                                                      </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={4}>
                                                                      {chain &&
                                                                          <InputField
                                                                              register={register}
                                                                              id={"publicAddress_" + chain.name + "_" + chain.network}
                                                                              placeholder="eg. 0x0000000000000000000000000000000000000000"
                                                                              label="Your public address"
                                                                              defaultValue={chain.publicAddress}
                                                                          />}


                                                                      <Accordion allowToggle
                                                                                 mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                                                        <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                                                          <h2>
                                                                            <AccordionButton>
                                                                              <Box as="span" flex='1' textAlign='left'>
                                                                                <Text mb='4px' mr='15px'
                                                                                      style={{whiteSpace: 'nowrap'}}>
                                                                                  Available tokens
                                                                                </Text>
                                                                              </Box>
                                                                              <AccordionIcon/>
                                                                            </AccordionButton>
                                                                          </h2>
                                                                          <AccordionPanel pb={4}>
                                                                            <Table variant="simple">
                                                                              <Tbody>
                                                                                {chain.tokens && chain.tokens.map((token: Token) => {
                                                                                  return <Tr>
                                                                                    <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                                                    <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>
                                                                                    <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                                                key={chain.name + chain.network + token.symbol}
                                                                                                colorScheme="brand"
                                                                                                defaultChecked={token.active}/></Td>
                                                                                  </Tr>;
                                                                                })}
                                                                              </Tbody>
                                                                            </Table>
                                                                          </AccordionPanel>
                                                                        </AccordionItem>
                                                                      </Accordion>
                                                                    </AccordionPanel>
                                                                  </AccordionItem>
                                                                </Accordion>
                                                              </Box>
                                                            ))}
                                                          </TabPanel>
                                                        {/***********************************   TESTNET   **********************************/}
                                                          <TabPanel>
                                                            {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                                              [key: string]: any
                                                            }, chain) => {
                                                              if (!acc[chain.name] && chain.network !== "mainnet") {
                                                                acc[chain.name] = chain;
                                                              }
                                                              return acc;
                                                            }, {})).map((chain) => (
                                                              <Box mb={2}>

                                                                <Accordion allowToggle outline={"0px solid #e0dddd"}
                                                                           borderStyle={"none"}>
                                                                  <AccordionItem borderStyle={"none"}>
                                                                    <h2>
                                                                      <AccordionButton>
                                                                        <Flex justifySelf={'flex-start'}
                                                                              minW={['1', null, '150px']}>
                                                                          <Switch mr={5} mt={1} colorScheme="brand"
                                                                                  {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                                                  key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                                                  defaultChecked={chain.active}
                                                                          />
                                                                          <Text fontWeight='bold' fontSize='lg'
                                                                                mb='5px'>
                                                                            {chainsDisplayNames[chain.name + "-" + chain.network]}
                                                                          </Text>
                                                                        </Flex>
                                                                        <Box as="span" flex='1' textAlign='left'>
                                                                        </Box>
                                                                        <AccordionIcon/>
                                                                      </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel pb={4}>
                                                                      {chain &&
                                                                          <InputField
                                                                              register={register}
                                                                              id={"publicAddress_" + chain.name + "_" + chain.network}
                                                                              placeholder="eg. 0x0000000000000000000000000000000000000000"
                                                                              label="Your public address"
                                                                              defaultValue={chain.publicAddress}
                                                                          />}

                                                                      <Accordion allowToggle
                                                                                 mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                                                        <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                                                          <h2>
                                                                            <AccordionButton>
                                                                              <Box as="span" flex='1' textAlign='left'>
                                                                                <Text mb='4px' mr='15px'
                                                                                      style={{whiteSpace: 'nowrap'}}>
                                                                                  Available tokens
                                                                                </Text>
                                                                              </Box>
                                                                              <AccordionIcon/>
                                                                            </AccordionButton>
                                                                          </h2>
                                                                          <AccordionPanel pb={4}>
                                                                            <Table variant="simple">
                                                                              <Tbody>
                                                                                {chain.tokens && chain.tokens.map((token: Token) => {
                                                                                  return <Tr>
                                                                                    <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                                                    <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>
                                                                                    <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                                                key={chain.name + chain.network + token.symbol}
                                                                                                colorScheme="brand"
                                                                                                defaultChecked={token.active}/></Td>
                                                                                  </Tr>;
                                                                                })}
                                                                              </Tbody>
                                                                            </Table>
                                                                          </AccordionPanel>
                                                                        </AccordionItem>
                                                                      </Accordion>
                                                                    </AccordionPanel>
                                                                  </AccordionItem>
                                                                </Accordion>
                                                              </Box>
                                                            ))}
                                                          </TabPanel>
                                                      </TabPanels>
                                                  </Tabs>
                                              </TabPanel>
                                          </TabPanels>
                                      </Tabs>


                                      <Flex justify="space-between" mt="24px">
                                          <Button
                                              variant="darkBrand"
                                              fontSize="sm"
                                              borderRadius="16px"
                                              w={{base: '128px', md: '148px'}}
                                              h="46px"
                                              ms="auto"
                                              onClick={handleSubmit(onSubmit)}
                                          >
                                              Continue
                                          </Button>
                                      </Flex>
                                  </Flex>
                              </Card>
                          </TabPanel>
                      </form>
                    {/***********************************   INVOICE WIZARD   **********************************/}
                      <form key={"payment-link-form"} id={"payment-link-form"}
                            onSubmit={handleSubmitPaymentLink(onSubmitPaymentLink)}>
                          <TabPanel
                              w={{sm: '330px', md: '700px', lg: '850px'}}
                              p="0px"
                              mx="auto"
                          >
                              <Card p="30px">
                                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                      Get paid
                                  </Text>
                                  <Text mt={4} mb={2}>Template</Text>
                                  <Controller
                                      name="pl_paymentTemplate"
                                      control={controlPaymentLink}
                                      rules={{required: 'Please select a template'}}
                                      render={({
                                                 field: {onChange, onBlur, value, name, ref}
                                               }) => (
                                        <FormControl id="pl_paymentTemplate">
                                          <Select
                                            key={'pl_paymentTemplate'}
                                            name={name}
                                            ref={ref}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={[
                                              {value: "INVOICE", label: 'Invoice'},
                                              {value: "LIGHT", label: 'Light'},
                                              // {value: "DONATION", label: 'Donation'},
                                              // {value: "TIP", label: 'Tip'},
                                              // {value: "PRODUCT", label: 'Product'}
                                            ]}
                                          />
                                        </FormControl>
                                      )}
                                  />
                                {errorsPaymentLink.pl_paymentTemplate &&
                                    <Text ml={0.5} mb={2}
                                          color={"red.400"}>{String(errorsPaymentLink.pl_paymentTemplate?.message)}</Text>}
                                  <Text mt={4} mb={2}>Concept</Text>
                                  <Textarea {...registerPaymentLink(`pl_concept`)}
                                            placeholder="Your payment concept"
                                            key="pl_concept"/>
                                  <Text mt={4} mb={2}>Recipient</Text>
                                  <Input {...registerPaymentLink('pl_recipient', {required: 'This field is required.'},)}
                                         placeholder="John Doe"
                                         key="pl_recipient"/>
                                {errorsPaymentLink.pl_recipient &&
                                    <Text ml={0.5} mb={2}
                                          color={"red.400"}>{String(errorsPaymentLink.pl_recipient?.message)}</Text>}
                                  <Text mt={4} mb={2}>Reference</Text>
                                  <Input {...registerPaymentLink(`pl_reference`)}
                                         placeholder=""
                                         key="pl_reference"/>
                                  <Text mt={4} mb={2}>Amount</Text>
                                  <Input {...registerPaymentLink('pl_amount', {
                                    required: 'This field is required.',
                                    pattern: {
                                      value: /^\d*\.?\d*$/,
                                      message: 'Please enter a valid number.',
                                    },
                                  })}/>
                                {errorsPaymentLink.pl_amount &&
                                    <Text ml={0.5} mb={2}
                                          color={"red.400"}>{String(errorsPaymentLink.pl_amount?.message)}</Text>}
                                {/* Editable Amount Input */}
                                  <Text mt={4} mb={2}>Editable amount</Text>
                                  <Controller
                                      name="isMinAmountEnabled"
                                      control={controlPaymentLink}
                                      defaultValue="false" // Make sure the default value is "false"
                                      render={({ field }) => (
                                        <RadioGroup {...field}>
                                          <Stack direction="row">
                                            <Radio value="true">Yes</Radio>
                                            <Radio value="false">No</Radio>
                                          </Stack>
                                        </RadioGroup>
                                      )}
                                  />

                                {/* Minimum Amount Input */}
                                  <FormControl mt={4}>
                                      <FormLabel htmlFor="pl_min_amount" color={isMinAmountEnabled === "false" ? "gray.400" : "black"}>
                                          Minimum Amount
                                      </FormLabel>
                                      <Input
                                          id="pl_min_amount"
                                          {...registerPaymentLink("pl_min_amount", {
                                            required: isMinAmountEnabled === "true" && "This field is required.",
                                            validate: (value) => {
                                              if (isMinAmountEnabled === "true") {
                                                const minAmount = parseFloat(value);
                                                const amount = parseFloat(watch("pl_amount"));
                                                if (!isNaN(minAmount) && !isNaN(amount) && amount < minAmount) {
                                                  return "Amount must be greater than or equal to minimum amount";
                                                }
                                                return true;
                                              }
                                              return true;
                                            },
                                            pattern: {
                                              value: /^\d+(\.\d+)?$/,
                                              message: "Please enter a valid number.",
                                            },
                                          })}
                                          disabled={isMinAmountEnabled !== "true"}
                                          placeholder="Minimum amount"
                                      />
                                    {errorsPaymentLink.pl_min_amount && (
                                      <Text ml={0.5} mb={2} color="red.400">
                                        {typeof errorsPaymentLink.pl_min_amount.message === "string" ? errorsPaymentLink.pl_min_amount.message : ""}
                                      </Text>
                                    )}
                                  </FormControl>
                                  <Text mt={4} mb={2}>Currency</Text>
                                  <Controller
                                      name="pl_currency"
                                      control={controlPaymentLink}
                                      rules={{required: 'This field is required.'}}
                                      render={({
                                                 field: {onChange, onBlur, value, name, ref}
                                               }) => (
                                        <FormControl id="pl_currency">
                                          <Select
                                            name={name}
                                            ref={ref}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isSearchable={true}
                                            options={groupedOptions}
                                            key={'pl_currency'}
                                          />
                                        </FormControl>
                                      )}
                                  />
                                {errorsPaymentLink.pl_currency &&
                                    <Text ml={0.5} mb={2}
                                          color={"red.400"}>{String(errorsPaymentLink.pl_currency?.message)}</Text>}
                                  <Flex justify="space-between" mt="24px">
                                      <Button
                                          variant="light"
                                          fontSize="sm"
                                          borderRadius="16px"
                                          w={{base: '128px', md: '148px'}}
                                          h="46px"
                                          onClick={() => productTab.current.click()}
                                      >
                                          Prev
                                      </Button>
                                      <Button
                                          variant="darkBrand"
                                          fontSize="sm"
                                          borderRadius="16px"
                                          w={{base: '128px', md: '148px'}}
                                          h="46px"
                                          isLoading={isFormSaving}
                                          type={"submit"}
                                      >
                                          Continue
                                      </Button>
                                  </Flex>
                              </Card>
                          </TabPanel>
                      </form>
                      <TabPanel
                          w={{sm: '330px', md: '700px', lg: '850px'}}
                          p="0px"
                          mx="auto"
                      >
                          <Card p="30px">
                              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="4">
                                  Notifications
                              </Text>
                              <Text fontSize='md' color={textColorSecondary} mb={10}>
                                  Once you get paid, check out the transactions in the{' '}
                                  <Text as='span' fontWeight='bold'>
                                      Notifications
                                  </Text>{' '}
                                  section
                              </Text>
                              <Image src={spcNotifications}/>

                              <Flex w="100%" justifyContent="flex-end">
                                  <Flex mt="24px">
                                      <Button
                                          mr={3}
                                          variant="light"
                                          fontSize="sm"
                                          borderRadius="16px"
                                          w={{base: '128px', md: '148px'}}
                                          h="46px"
                                          onClick={handleCopyLinkClick}
                                      >
                                          Copy link
                                      </Button>
                                      <Button
                                          variant="darkBrand"
                                          fontSize="sm"
                                          borderRadius="16px"
                                          w={{base: '128px', md: '148px'}}
                                          h="46px"
                                          onClick={() => window.location.replace("/#/admin/get-paid")}
                                      >
                                          Payment links
                                      </Button>
                                  </Flex>
                              </Flex>
                          </Card>
                      </TabPanel>
                  </TabPanels>
              </Tabs>
          </Flex>
      }
    </>

  );
}
