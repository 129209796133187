import axios, {AxiosError} from "axios";

export async function getMerchantPaymentSettings(setPaymentSettings: (data: any) => void) {

  try {
    return await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwtToken')
      }
    });
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
      window.location.replace("#/auth/set-up");
    }
    if (err.response?.status === 404) {
      console.log("Merchant Not Found")
      await createNewPaymentSettings(setPaymentSettings)
    }
  }
}

export async function createNewPaymentSettings(setPaymentSettings: (data: any) => void) {
  try {
    var defaultPaymentSettings = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', {}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwtToken')
      }
    });
    setPaymentSettings(defaultPaymentSettings.data);
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
      window.location.replace("/#/auth/set-up");
    }
  }
}

export async function getAPIKeys() {

  try {
    return await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/keys', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwtToken')
      },
      withCredentials: true
    });
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
      console.log("Unauthorized")
      window.location.replace("/#/auth/set-up");
    }
  }
}

export async function generateAPIKey(setApiKeys: (data: any) => void) {

  try {
    var keys = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/keys', {}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwtToken')
      },
      withCredentials: true
    });
    setApiKeys(keys.data);
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
      window.location.replace("#/auth/set-up");
    }
  }
}

export async function saveSiteUrl(authorizedSiteUrl: string) {
  try {
    var webhooksResponse = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/site/url', {"siteUrl": authorizedSiteUrl}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwtToken')
      },
      withCredentials: true
    });
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
      window.location.replace("/#/auth/set-up");
    }
  }
}