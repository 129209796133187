export const chainsDisplayNames: Record<string, string> = {
  "ethereum-mainnet": "Ethereum",
  "zksync-goerli": "zkSync Era (goerli)",
  "zksync-mainnet": "zkSync Era",
  "polygon-mumbai": "Polygon zkEVM (mumbai)",
  "polygon-mainnet": "Polygon",
  "polygonzkevm-mainnet": "Polygon zkEVM",
  "optimism-mainnet": "Optimism",
  "optimism-goerli": "Optimism (goerli)",
  "avalanche-fuji": "Avalanche (fuji)",
  "avalanche-mainnet": "Avalanche",
  "arbitrum-mainnet": "Arbitrum",
  "scroll-mainnet": "Scroll",
  "linea-mainnet": "Linea",
  "base-mainnet": "Base",
  "binance-mainnet": "Binance BNB"
}